import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getLocalText } from '@/utilities/language';
import withPalette from './index';
function MaterialSingleSelect(_ref) {
  var _formAnswer$selected, _option;
  var options = _ref.options,
    formAnswer = _ref.formAnswer,
    _onChange = _ref.onChange,
    disabledOptIds = _ref.disabledOptIds;
  var txt = getLocalText('mobile.selected');
  var answer = (_formAnswer$selected = formAnswer.selected) === null || _formAnswer$selected === void 0 ? void 0 : _formAnswer$selected[0];
  var option = options.find(function (e) {
    return e.id === answer;
  });
  var value = option ? {
    label: window.showMultiLang ? option === null || option === void 0 ? void 0 : (_option = option["".concat(window.language, "Text")]) === null || _option === void 0 ? void 0 : _option.html2Text() : option.text.html2Text(),
    value: option.id
  } : {
    label: '',
    value: ''
  };
  return React.createElement(Autocomplete, {
    options: options.filter(function (e) {
      return e.id;
    }).map(function (el) {
      var _el;
      return {
        label: window.showMultiLang ? el === null || el === void 0 ? void 0 : (_el = el["".concat(window.language, "Text")]) === null || _el === void 0 ? void 0 : _el.html2Text() : el.text.html2Text(),
        value: el.id
      };
    }),
    getOptionLabel: function getOptionLabel(option) {
      return option.label;
    },
    value: value,
    onChange: function onChange(event, newValue) {
      _onChange.selected((newValue === null || newValue === void 0 ? void 0 : newValue.value) || '');
    },
    getOptionSelected: function getOptionSelected(option, value) {
      return option.value === value.value;
    },
    getOptionDisabled: function getOptionDisabled(opt) {
      return disabledOptIds.includes(opt.value);
    },
    renderInput: function renderInput(params) {
      return React.createElement(TextField, _extends({}, params, {
        placeholder: answer ? '' : txt,
        variant: "outlined",
        fullWidth: true,
        InputLabelProps: {
          shrink: true
        }
      }));
    }
  });
}
export default withPalette(MaterialSingleSelect);