import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _Icon from "antd/es/icon";
import React from 'react';
import env from '@/env';
var IconFont = _Icon.createFromIconfontCN({
  scriptUrl: env.cdn.iconfont
});
export default function IconFontComponent(_ref) {
  var type = _ref.type,
    _ref$style = _ref.style,
    style = _ref$style === void 0 ? {} : _ref$style,
    _ref$className = _ref.className,
    className = _ref$className === void 0 ? '' : _ref$className,
    _ref$color = _ref.color,
    color = _ref$color === void 0 ? 'var(--text-color)' : _ref$color;
  return React.createElement(IconFont, {
    type: type,
    className: className,
    style: _objectSpread({}, style, {
      color: color
    })
  });
}