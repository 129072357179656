import { createMuiTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
export var theme = function theme(presetTheme) {
  return presetTheme ? createMuiTheme({
    palette: {
      primary: {
        main: presetTheme.amEntityBg.replace(' !important', ''),
        contrastText: presetTheme.textColor.replace(' !important', '')
      },
      secondary: {
        main: presetTheme.amEntityBg.replace(' !important', ''),
        contrastText: presetTheme.textColor.replace(' !important', '')
      }
    },
    overrides: {
      MuiAutocomplete: {
        root: {
          borderRadius: 4,
          color: presetTheme.textColor.replace(' !important', '')
        }
      },
      MuiOutlinedInput: {
        root: {
          color: presetTheme.textColor.replace(' !important', ''),
          position: 'relative',
          '& $notchedOutline': {
            borderColor: presetTheme.antEntityBorder.replace(' !important', '')
          },
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: presetTheme.antEntityBorder.replace(' !important', ''),
            '@media (hover: none)': {
              borderColor: presetTheme.antEntityBorder.replace(' !important', '')
            }
          },
          '&$focused $notchedOutline': {
            borderColor: presetTheme.antEntityBorder.replace(' !important', ''),
            borderWidth: 1
          }
        }
      },
      MuiSvgIcon: {
        root: {
          color: presetTheme.textColor.replace(' !important', '')
        }
      },
      MuiInputLabel: {
        root: {
          color: presetTheme.textColor.replace(' !important', '')
        }
      },
      MuiFormLabel: {
        root: {
          color: presetTheme.textColor.replace(' !important', '')
        }
      },
      MuiIconButton: {
        root: {
          color: presetTheme.textColor.replace(' !important', '')
        }
      }
    }
  }) : createMuiTheme({
    palette: {
      primary: {
        main: '#3370ff'
      }
    }
  });
};
export var PrettoSlider = withStyles({
  root: {
    height: 12,
    color: 'var(--text-color)'
  },
  thumb: {
    height: 30,
    width: 30,
    marginTop: -12,
    marginLeft: -16,
    background: 'var(--slider-thumb)',
    color: 'var(--primary-color)',
    border: '2px solid var(--primary-color)',
    boxShadow: '0 1px 4px 1px rgba(0,0,0,.2)'
  },
  active: {},
  valueLabel: {
    top: 7,
    left: 'calc(-50% + 10px)',
    '& *': {
      background: 'transparent',
      color: 'var(--primary-color)'
    }
  },
  marked: {
    marginTop: 20,
    marginBottom: 5
  },
  track: {
    height: 8,
    borderRadius: 4,
    color: 'var(--primary-color)'
  },
  rail: {
    height: 8,
    opacity: 0.4,
    borderRadius: 4,
    color: 'var(--primary-color)'
  },
  mark: {
    backgroundColor: 'var(--text-color-secondary)',
    height: 2,
    width: 2,
    marginTop: 3
  },
  markLabel: {
    fontSize: 12,
    fontWeight: 550,
    color: 'var(--text-color)',
    top: -25
  },
  markLabelActive: {
    color: 'var(--primary-color)'
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'var(--question-bg)'
  }
})(Slider);
export var useStyles = makeStyles(function (theme) {
  return {
    root: {
      width: 300 + theme.spacing(3) * 2
    },
    margin: {
      height: theme.spacing(3)
    }
  };
});