import React from 'react';
import withPalette from './index';
import { useStyles } from '../../../common/Question/MaterialUI/materialTheme';
import Slider from '../../../common/Question/MaterialUI/MaterialSlider';
function MaterialSlider(props) {
  var classes = useStyles();
  return React.createElement("div", {
    style: {
      width: '86%',
      marginLeft: '7%'
    },
    className: classes.root
  }, React.createElement(Slider, props));
}
export default withPalette(MaterialSlider);