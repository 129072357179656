// export const navigatorLang = (navigator.language || 'en')
//   .toLowerCase()
//   .includes('en')
//   ? 'en'
//   : 'cn';
export var navigatorLang = function navigatorLang(language, multiLanguage, bilingual, localLanguage) {
  var multiLngList = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  var navigatorLanguage = convertLanguage(navigator.language);
  if (language) {
    if (bilingual) {
      if (navigatorLanguage === 'en') {
        return 'en';
      }
      return 'cn';
    } else if (multiLanguage) {
      var idx = multiLngList.findIndex(function (l) {
        return l.language === navigatorLanguage;
      });
      return idx > -1 ? navigatorLanguage : localLanguage;
    }
    return language;
  }
  return navigatorLanguage;
};
export function getLocalText(key) {
  var select = (languageMap[key] || {})[window.language || 'cn'];
  if (typeof select === 'string') {
    return select;
  }
  if (select === undefined) {
    return languageMap[key].en;
  }
  for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    rest[_key - 1] = arguments[_key];
  }
  return select.apply(void 0, rest);
}
export var convertLanguage = function convertLanguage() {
  var lang = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var language = lang.toLowerCase();
  if (language.includes('zh-TW')) {
    // 繁体
    return 'tc';
  } else if (language.includes('zh')) {
    // 中文
    return 'cn';
  } else if (language.includes('en')) {
    // 英文
    return 'en';
  } else if (language.includes('ja')) {
    // 日语
    return 'ja';
  } else if (language.includes('ko')) {
    // 韩语
    return 'ko';
  } else if (language.includes('pt')) {
    // 葡萄牙语
    return 'pt';
  } else if (language.includes('de')) {
    // 德语
    return 'de';
  } else if (language.includes('fr')) {
    // 法语
    return 'fr';
  } else if (language.includes('it')) {
    // 意大利语
    return 'it';
  } else if (language.includes('hi')) {
    // 印地语
    return 'hi';
  } else if (language.includes('ta')) {
    // 泰米尔语
    return 'ta';
  } else if (language.includes('te')) {
    // 泰卢固语
    return 'te';
  } else if (language.includes('hi')) {
    // 印地语
    return 'hi';
  } else if (language.includes('ml')) {
    // Malayalam
    return 'ml';
  } else if (language.includes('kn')) {
    // Kannada
    return 'kn';
  } else if (language.includes('mr')) {
    // Marathi
    return 'mr';
  } else if (language.includes('gu')) {
    // Gujarati
    return 'gu';
  } else if (language.includes('bn')) {
    // Bengali
    return 'bn';
  } else if (language.includes('pa')) {
    // Punjabi
    return 'pa';
  } else if (language.includes('bh')) {
    // Bhojpuri
    return 'bh';
  } else if (language.includes('id')) {
    // Indonesia
    return 'id';
  } else if (language.includes('ar')) {
    // 阿语العربية
    return 'ar';
  } else if (language.includes('tr')) {
    // 土耳其语
    return 'tr';
  } else if (language.includes('ms') || language.includes('my')) {
    // 马来西亚语
    return 'my';
  } else if (language.includes('ru')) {
    // 俄语
    return 'Russian';
  } else if (language.includes('es')) {
    // 西班牙语
    return 'Spanish';
  } else if (language.includes('th')) {
    // 泰语
    return 'Thai';
  } else if (language.includes('vi')) {
    // 越南语
    return 'Vietnamese';
  } else if (language.includes('fil')) {
    // 菲律宾语
    return 'Filipino';
  }
  return null;
};
var languageMap = {
  'RequestError.400': {
    cn: '请求错误',
    tc: '請求錯誤',
    en: 'Something went wrong. Please try again later.',
    ja: '要求にエラーが発生しました',
    ko: '잘못된 요청',
    pt: 'Solicitação inválida',
    de: 'Anforderungsfehler',
    fr: 'Requête incorrecte',
    it: 'Richiesta errata',
    hi: 'खराब आग्रह',
    ta: 'தவறான கோரிக்கை',
    te: 'తప్పుడు విన్నపం',
    ml: 'തെറ്റായ അഭ്യര്‍ത്ഥന',
    kn: 'ಕೆಟ್ಟ ಮನವಿ',
    mr: 'खराब विनंती',
    gu: 'અમાન્ય વિનંતી',
    bn: 'ব্যাড রিকোয়েস্ট',
    pa: 'ਬੁਰੀ ਗੁਜਾਰਸ਼',
    bh: 'खराब आग्रह',
    id: 'Permohonan salah',
    ar: 'طلب غير صالح',
    tr: 'Geçersiz İstek',
    my: 'Permintaan tidak berkenaan',
    Russian: 'Неверный запрос',
    Spanish: 'Solicitud errónea',
    Thai: 'คำขอไม่ถูกต้อง',
    Vietnamese: 'Yêu cầu không hợp lệ',
    Filipino: 'Maling kahilingan'
  },
  'RequestError.404': {
    cn: '资源未找到',
    tc: '資源未找到',
    en: 'Page not found',
    ja: '指定されたページが見つかりません',
    ko: '찾을 수 없음',
    pt: 'Não encontrado',
    de: 'Nicht gefunden',
    fr: 'Ressource non trouvée',
    it: 'Nessun risultato',
    hi: 'नहीं मिला',
    ta: 'கிடைக்கவில்லை',
    te: 'దొరకలేదు',
    ml: 'കാണാനില്ല',
    kn: 'ದೊರಕುತ್ತಿಲ್ಲ',
    mr: 'सापडले नाही',
    gu: 'મળ્યું નહીં',
    bn: 'খুঁজে পাওয়া যাচ্ছে না',
    pa: 'ਨਹੀਂ ਲਭਿਆ',
    bh: 'नहीं मिला',
    id: 'Tidak ditemukan',
    ar: 'غير موجود',
    tr: 'Bulunamadı',
    my: 'Tidak ditemui',
    Russian: 'Не обнаружено ',
    Spanish: 'No encontrado',
    Thai: 'ไม่พบ',
    Vietnamese: 'Không tìm thấy',
    Filipino: 'Hindi nahanap'
  },
  'RequestError.403': {
    cn: '没有访问权限',
    tc: '沒有訪問權限',
    en: "You don't have access to this feature",
    ja: 'アクセス許可がありません',
    ko: '접근 권한이 없음',
    pt: 'Proibido',
    de: 'Keine Zugriffsrechte',
    fr: 'Accès refusé',
    it: 'Proibito',
    hi: 'हटा दिया गया है',
    ta: 'தடைசெய்யப்பட்ட',
    te: 'నిషేధించబడింది',
    ml: 'വിലക്കപ്പെട്ടത്',
    kn: 'ನಿಷೇಧಿಸಲಾಗಿದೆ',
    mr: 'निषिद्ध',
    gu: 'પ્રતિબંધિત',
    bn: 'নিষিদ্ধ',
    pa: 'ਪਾਬੰਦੀ',
    bh: 'हटा दिया गया है',
    id: 'Tidak ada akses',
    ar: 'ممنوع',
    tr: 'Yasaklanmış',
    my: 'Dilarang',
    Russian: 'Нет доступа ',
    Spanish: 'No permitido',
    Thai: 'ไม่สามารถเข้าถึงได้',
    Vietnamese: 'Bị chặn',
    Filipino: 'Ipinagbabawal'
  },
  'RequestError.401': {
    cn: '请求未授权',
    tc: '請求未授權',
    en: "You don't have access to this feature",
    ja: 'アクセス許可が承認されていません',
    ko: '요청이 위임되지 않음',
    pt: 'Não autorizado',
    de: 'Nicht autorisiert',
    fr: 'Non autorisé',
    it: 'Non autorizzato',
    hi: 'अनऑथराइज्ड',
    ta: 'அங்கீகரிக்கப்படாத',
    te: 'అనధికార',
    ml: 'നിയമാനുസൃതമല്ലാത്ത',
    kn: 'ಅನಧಿಕೃತ',
    mr: 'अनधिकृत',
    gu: 'અનધિકૃત',
    bn: 'অনধিকৃত',
    pa: 'ਅਣਅਧਿਕਾਰਤ',
    bh: 'अनऑथराइज्ड',
    id: 'Permohonan belum diberikan',
    ar: 'مَحْظُور',
    tr: 'Yetkisiz istek',
    my: 'Tidak dibenarkan',
    Russian: 'Запрос не авторизован',
    Spanish: 'Sin autorización',
    Thai: 'คำขอไม่ได้รับอนุญาต',
    Vietnamese: 'Không có quyền truy cập',
    Filipino: 'Hindi awtorisado'
  },
  'RequestError.biggerThan400': {
    cn: '客户端错误',
    tc: '客戶端錯誤',
    en: 'Something went wrong. Please try again later.',
    ja: 'クライアント側でエラーが発生しました',
    ko: '클라이언트 오류',
    pt: 'Erro do cliente',
    de: 'Client-Fehler',
    fr: 'Erreur du client',
    it: 'Errore del client',
    hi: 'क्लाइंट एरर',
    ta: 'கிளையன்ட் பிழை',
    te: 'క్లయింట్ లోపం',
    ml: 'ക്ലൈന്‍റ് എറര്‍',
    kn: 'ಕ್ಲೈಂಟ್ ದೋಷ',
    mr: 'क्लायंट त्रुटी',
    gu: 'ક્લાઈન્ટ એરર',
    bn: 'ক্লায়েন্ট এরর',
    pa: 'ਕਲਾਇੰਟ ਐਰਰ',
    bh: 'क्लाइंट एरर',
    id: 'Klien eror',
    ar: 'خطأ في العميل',
    tr: 'Kullanıcı Hatası',
    my: 'Ralat Klien',
    Russian: 'Ошибка клиента ',
    Spanish: 'Error de cliente',
    Thai: 'ระบบลูกค้าเกิดข้อผิดพลาด',
    Vietnamese: 'Lỗi máy khách',
    Filipino: 'Error sa kliyente'
  },
  'RequestError.biggerThan500': {
    cn: '服务器错误',
    tc: '服務器錯誤',
    en: 'Server Error',
    ja: 'サーバーエラーが発生しました',
    ko: '서버 오류',
    pt: 'Erro do servidor',
    de: 'Serverfehler',
    fr: 'Erreur du serveur',
    it: 'Errore del server',
    hi: 'सर्वर एरर',
    ta: 'சர்வர் பிழை',
    te: 'సర్వర్ లోపం',
    ml: 'സെര്‍വര്‍ എറര്‍',
    kn: 'ಸರ್ವರ್ ದೋಷ',
    mr: 'सर्व्हर त्रुटी',
    gu: 'સર્વર એરર',
    bn: 'সার্ভার এরর',
    pa: 'ਸਰਵਰ ਐਰਰ',
    bh: 'सर्वर एरर',
    id: 'Server eror',
    ar: 'خطأ في الخادم',
    tr: 'Server Hatası',
    my: 'Ralat Pelayan',
    Russian: 'Ошибка сервера ',
    Spanish: 'Error de servidor',
    Thai: 'เซิร์ฟเวอร์ผิดพลาด',
    Vietnamese: 'Lỗi máy chủ',
    Filipino: 'Error sa server'
  },
  'RequestError.unknow': {
    cn: '未知错误',
    tc: '未知錯誤',
    en: 'Unknown Error',
    ja: '未知のエラーが発生しました',
    ko: '알 수 없는 오류',
    pt: 'Erro desconhecido',
    de: 'Unbekannter Fehler',
    fr: 'Erreur non identifiée',
    it: 'Errore sconosciuto',
    hi: 'अज्ञात एरर',
    ta: 'அறியப்படாத பிழை',
    te: 'గుర్తించలేని పొరపాటు',
    ml: 'തിരിച്ചറിയാന്‍ കഴിയാത്ത എറര്‍',
    kn: 'ಅಜ್ಞಾತ ದೋಷ',
    mr: 'अज्ञात त्रुटी',
    gu: 'અનનોન એરર',
    bn: 'অজ্ঞাত এরর',
    pa: 'ਅਣਜਾਣ ਐਰਰ',
    bh: 'अज्ञात एरर',
    id: 'Eror tidak diketahui',
    ar: 'خطأ غير معروف',
    tr: 'Bilinmeyen Hata',
    my: 'Ralat Tidak Diketahui',
    Russian: 'Неизвестная ошибка',
    Spanish: 'Error desconocido',
    Thai: 'ข้อผิดพลาดที่ไม่รู้จัก',
    Vietnamese: 'Không rõ lỗi',
    Filipino: 'Di-alam na error'
  },
  'Content.Form.error.message': {
    cn: '问卷还有题目未完成',
    tc: '問卷還有題目未完成',
    en: 'Please answer all required questions',
    ja: 'フォーム認証に失敗しました',
    ko: '양식 심사에 통과하지 못함',
    pt: 'Falha na validação do formulário',
    de: 'Formularüberprüfung fehlgeschlagen',
    fr: 'La validation du formulaire ne fonctionne pas',
    it: 'Non carica il modulo',
    hi: 'फॉर्म वेलिडेशन काम नहीं कर रहा',
    ta: 'படிவம் சரிபார்த்தல் வேலை செய்யவில்லை',
    te: 'ఫారమ్ ధ్రువీకరణ పని కాదు',
    ml: 'ഫോം മൂല്യനിർണ്ണയം പ്രവർത്തിക്കുന്നില്ല',
    kn: 'ಮೌಲ್ಯಮಾಪನ ಕಾರ್ಯ ನಿರ್ವಹಿಸುತ್ತಿಲ್ಲ',
    mr: 'फॉर्म प्रमाणीकरण काम करत नाही',
    gu: 'ફોર્મ માન્યતા કામ કરી રહી નથી',
    bn: 'ফর্ম বৈধতা সম্পর্কিত কাজ করা যাচ্ছে না',
    pa: 'ਫਾਰਮ ਪ੍ਰਮਾਣਿਕਤਾ ਕੰਮ ਨਹੀਂ ਕਰ ਰਿਹਾ',
    bh: 'फॉर्म वेलिडेशन काम नहीं कर रहा',
    id: 'Masih ada pertanyaan yang belum dijawab.',
    ar: 'يرجى الإجابة على جميع الأسئلة قبل الارسال',
    tr: 'Gönderebilmek için lütfen gerekli bütün soruları cevaplayınız',
    my: 'Sila jawab semua soalan wajib untuk serahan',
    Russian: 'Пожалуйста, ответьте на все вопросы, помеченные звёздочкой.',
    Spanish: 'Por favor responde todas las preguntas requeridas para poder enviar',
    Thai: 'โปรดตอบคำถามที่จำเป็นทั้งหมดเพื่อส่ง',
    Vietnamese: 'Trả lời tất cả câu hỏi để hoàn thành bảng khảo sát',
    Filipino: 'Pakisagot ng lahat hinihinging tanong bago isumite'
  },
  'Content.Form.submitting': {
    cn: '提交中…',
    tc: '提交中',
    en: 'Processing',
    ja: '送信中',
    ko: '제출 중',
    pt: 'Processando',
    de: 'Wird gesendet....',
    fr: 'Traitement en cours...',
    it: 'In lavorazione',
    hi: 'प्रोसेस हो रहा है',
    ta: 'செயலாக்கத்தில் உள்ளது',
    te: 'ప్రోసెసింగ్',
    ml: 'പ്രൊസസിങ്',
    kn: 'ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲಾಗುತ್ತಿದೆ',
    mr: 'प्रक्रिया',
    gu: 'પ્રક્રિયા થઇ રહી છે',
    bn: 'প্রসেস হচ্ছে',
    pa: 'ਪ੍ਰੋਸੈਸਿੰਗ',
    bh: 'प्रोसेस हो रहा है',
    id: 'Sedang diproses',
    ar: 'معالجه',
    tr: 'İşleniyor',
    my: 'Sedang diproses',
    Russian: 'Идет обработка ...',
    Spanish: 'Procesando',
    Thai: 'กำลังส่ง',
    Vietnamese: 'Đang gửi đi',
    Filipino: 'Pinoproseso...'
  },
  'Content.Form.loading': {
    cn: '加载中…',
    tc: '加載中',
    en: 'Loading…',
    ja: '読み込み中',
    ko: '로딩 중',
    pt: 'Carregando...',
    de: 'Wird geladen....',
    fr: 'En cours de chargement...',
    it: 'Caricamento in corso…',
    hi: 'लोडिंग...',
    ta: 'லோடிங்...',
    te: 'లోడ్ అవుతుంది',
    ml: 'ലോഡിങ്...',
    kn: 'ಲೋಡಿಂಗ್..',
    mr: 'लोड करीत आहे ...',
    gu: 'લોડ થઇ રહ્યું છે...',
    bn: 'লোড হচ্ছে',
    pa: 'ਲੋਡ ਹੋ ਰਿਹਾ ਹੈ ...',
    bh: 'लोडिंग...',
    id: 'Sedang memuat',
    ar: 'تحميل...',
    tr: 'Yükleniyor...',
    my: 'Sedang dimuat',
    Russian: 'Загрузка...',
    Spanish: 'Cargando',
    Thai: 'กำลังโหลด...',
    Vietnamese: 'Đang tải...',
    Filipino: 'Naglo-load'
  },
  'Content.Form.submit.error': {
    cn: '提交失败',
    tc: '提交失敗',
    en: "Couldn't submit",
    ja: '送信に失敗しました',
    ko: '제출 실패',
    pt: 'Falha no envio',
    de: 'Einreichung fehlgeschlagen',
    fr: 'La validation a échoué',
    it: 'Operazione non riuscita',
    hi: 'सबमिशन फेल',
    ta: 'சமர்ப்பிப்பு தோல்வியடைந்தது',
    te: 'సమర్పణ విఫలమైంది',
    ml: 'സബ്മിഷന്‍ പരാജയപ്പെട്ടു',
    kn: 'ಸಲ್ಲಿಕೆ ವಿಫಲವಾಗಿದೆ',
    mr: 'सबमिशन अयशस्वी',
    gu: 'સબમિશન ફેઈલ ગયું',
    bn: 'সাবমিশন ফেল হয়ে গিয়েছে',
    pa: 'ਸਬਮਿਸ਼ਨ ਅਸਫਲ',
    bh: 'सबमिशन फेल',
    id: 'Gagal mengirim',
    ar: 'فشل الإرسال',
    tr: 'Gönderme Başarısız Oldu',
    my: 'Serahan gagal',
    Russian: 'Не удалось отправить',
    Spanish: 'Solicitud fallida',
    Thai: 'การส่งล้มเหลว',
    Vietnamese: 'Gửi thất bại',
    Filipino: 'Nabigo ang submisyon'
  },
  'Content.Form.Ok': {
    cn: '确定',
    tc: '確定',
    en: 'OK',
    ja: '確認',
    ko: '확인',
    pt: 'OK',
    de: 'OK',
    fr: 'OK',
    it: 'OK',
    hi: 'ओके',
    ta: 'ஓகே',
    te: 'అలాగే',
    ml: 'ഒകെ',
    kn: 'ಸರಿ',
    mr: 'ठीक आहे',
    gu: 'ઓકે',
    bn: 'ঠিক আছে',
    pa: 'ਠੀਕ ਹੈ',
    bh: 'ओके',
    id: 'OK',
    ar: 'موافق',
    tr: 'Tamam',
    my: 'OK',
    Russian: 'Подтвердить',
    Spanish: 'OK',
    Thai: 'ยืนยัน',
    Vietnamese: 'OK',
    Filipino: 'OK'
  },
  'Content.Form.Cancel': {
    cn: '取消',
    tc: '取消',
    en: 'Cancel',
    ja: 'キャンセル',
    ko: '취소',
    pt: 'Cancelar',
    de: 'Stornieren',
    fr: 'Annuler',
    it: 'Annulla',
    hi: 'रद्द करना',
    ta: 'ரத்து செய்',
    te: 'రద్దు చేయండి',
    ml: 'റദ്ദാക്കുക',
    kn: 'ರದ್ದುಮಾಡಿ',
    mr: 'रद्द करा',
    gu: 'રદ કરો',
    bn: 'বাতিল করুন',
    pa: 'ਰੱਦ ਕਰੋ',
    bh: 'मना कइ दीं',
    id: 'Membatalkan',
    ar: 'يلغي',
    tr: 'İptal etmek',
    my: 'Batal',
    Russian: 'Отмена',
    Spanish: 'Cancelar',
    Thai: 'ยกเลิก',
    Vietnamese: 'Hủy bỏ',
    Filipino: 'Kanselahin'
  },
  'Content.Form.submit': {
    cn: '提交',
    tc: '提交',
    en: 'Submit',
    ja: '送信',
    ko: '제출하기',
    pt: 'Enviar',
    de: 'Bestätigen',
    fr: 'Valider',
    it: 'Invia',
    hi: 'जमा करें',
    ta: 'சமர்ப்பிக்க',
    te: 'సమర్పించండి',
    ml: 'സബ്മിറ്റ്',
    kn: 'ಸಲ್ಲಿಕೆ',
    mr: 'सबमिट करा',
    gu: 'સબમિટ કરો',
    bn: 'সাবমিট',
    pa: 'ਜਮ੍ਹਾਂ ਕਰੋ',
    bh: 'जमा करें',
    id: 'Kirim',
    ar: 'إرسال',
    tr: 'Gönder',
    my: 'Serah',
    Russian: 'Отправить',
    Spanish: 'Enviar',
    Thai: 'ส่ง',
    Vietnamese: 'Gửi',
    Filipino: 'Isumite'
  },
  'Content.Form.next.page': {
    cn: '下一页',
    tc: '下一頁',
    en: 'Next',
    ja: '次へ',
    ko: '다음 페이지',
    pt: 'Próximo',
    de: 'Nächste Seite',
    fr: 'Étape suivante',
    it: 'Il prossimo',
    hi: 'अगला',
    ta: 'அடுத்த',
    te: 'తరువాత',
    ml: 'അടുത്തത്',
    kn: 'ಮುಂದೆ',
    mr: 'पुढे',
    gu: 'આગળનું',
    bn: 'পরবর্তী',
    pa: 'ਅਗਲਾ',
    bh: 'अगला',
    id: 'Lanjut',
    ar: 'التالي',
    tr: 'Sonraki Sayfa',
    my: 'Seterusnya',
    Russian: 'Далее',
    Spanish: 'Siguiente',
    Thai: 'ถัดไป',
    Vietnamese: 'Tiếp',
    Filipino: 'Susunod'
  },
  'Content.Form.last.page': {
    cn: '上一页',
    tc: '上一頁',
    en: 'Previous page',
    tr: 'Önceki Sayfa',
    id: 'Halaman Sebelumnya'
  },
  'placeholder.other': {
    cn: '其他',
    tc: '其他',
    en: 'Other',
    ja: 'その他',
    ko: '기타',
    pt: 'Outro',
    de: 'Sonstiges',
    fr: 'Autres',
    it: 'Altri',
    hi: 'दूसरा',
    ta: 'மற்ற',
    te: 'ఇతర',
    ml: 'മറ്റുള്ളവ',
    kn: 'ಇತರೆ',
    mr: 'इतर',
    gu: 'અન્ય',
    bn: 'অন্যান্য',
    pa: 'ਹੋਰ',
    bh: 'दूसरा',
    id: 'Lain-lain',
    ar: 'أخرى',
    tr: 'Diğer',
    my: 'Lain-lain',
    Russian: 'Другое',
    Spanish: 'Otro',
    Thai: 'อื่นๆ',
    Vietnamese: 'Khác',
    Filipino: 'Iba'
  },
  'placeholder.clickToChoose': {
    cn: '点击选择',
    tc: '點擊選擇',
    en: 'Select',
    ja: '選択してくだい',
    ko: '선택하기',
    pt: 'Selecionar',
    de: 'Zur Auswahl klicken',
    fr: 'Sélectionner',
    it: 'Seleziona',
    hi: 'चुनें',
    ta: 'தேர்ந்தெடு',
    te: 'ఎంచుకోండి',
    ml: 'തിരഞ്ഞെടുക്കുക',
    kn: 'ಆಯ್ಕೆಮಾಡಿ',
    mr: 'निवडा',
    gu: 'પસંદ કરો',
    bn: 'বেছে নিন',
    pa: 'ਚੁਣੋ',
    bh: 'चुनें',
    id: 'Pilih',
    ar: 'حدد',
    tr: 'Seç',
    my: 'Pilih',
    Russian: 'Выбрать',
    Spanish: 'Elegir',
    Thai: 'กดเพื่อเลือก',
    Vietnamese: 'Chọn',
    Filipino: 'Pili'
  },
  'Footer.pages': {
    cn: '页',
    tc: '頁',
    en: 'Page/Pages',
    ja: 'ページ',
    ko: '페이지',
    pt: 'Página/Páginas',
    de: 'Seite/Seiten',
    fr: 'Page/Pages',
    it: 'Pagina/Pagine',
    hi: 'पेज/ पेजेस',
    ta: 'பக்கம் / பக்கங்கள்',
    te: 'పేజీ / పేజీలు',
    ml: 'പേജ്/ പേജുകള്‍',
    kn: 'ಪುಟ / ಪುಟಗಳು',
    mr: 'पृष्ठ / पृष्ठे',
    gu: 'પેજ/પેજીસ',
    bn: 'পেজ/পেজগুলি',
    pa: 'ਪੰਨਾ / ਪੰਨੇ',
    bh: 'पेज/ पेजेस',
    id: 'Halaman',
    ar: 'الصفحة/الصفحات',
    tr: 'Sayfa/Sayfalar',
    my: 'Halaman',
    Russian: 'Страница',
    Spanish: 'Página/páginas',
    Thai: 'หน้า',
    Vietnamese: 'Trang',
    Filipino: 'Pahina'
  },
  'SingleChoice.Selected': {
    cn: '请完成作答',
    tc: '請完成作答',
    en: 'Please respond to each question',
    ja: '回答を記入してください',
    ko: '답을 입력해 주세요.',
    pt: 'Favor preencher com resposta',
    de: 'Bitte die Antwort eingeben',
    fr: 'Veuillez entrer une réponse',
    it: 'Per favore compila',
    hi: 'कृपया जवाब भरें',
    ta: 'தயவுசெய்து ஒரு பதிலை நிரப்பவும்',
    te: 'దయచేసి ఒక సమాధానం పూరించండి',
    ml: 'ദയവായി ഒരു ഉത്തരം പൂരിപ്പിക്കുക',
    kn: 'ದಯವಿಟ್ಟು ಉತ್ತರವನ್ನು ಭರ್ತಿ ಮಾಡಿ',
    mr: 'कृपया उत्तर भरा',
    gu: 'કૃપા કરીને જવાબ ભરો',
    bn: 'দয়া করে উত্তর দিন',
    pa: 'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਉੱਤਰ ਭਰੋ',
    bh: 'कृपया जवाब भरें',
    id: 'Silakan isi jawaban',
    ar: 'يرجى ترك إجابة',
    tr: 'Lütfen bir cevap belirtiniz',
    my: 'Sila isi jawapan',
    Russian: 'Пожалуйста, укажите ответ',
    Spanish: 'Por favor rellenar una respueta',
    Thai: 'โปรดกรอกคำตอบ',
    Vietnamese: 'Hãy điền câu trả lời',
    Filipino: 'Pakipuno ng isang sagot'
  },
  'SingleChoice.otherText': {
    cn: '请填写其他',
    tc: '請填寫其他',
    en: 'If "Other", please specify',
    ja: 'その他を記入してください',
    ko: '명시해주세요',
    pt: 'Favor preencher "outros" se necessário',
    de: 'Bitte ggf. Sonstiges ausfüllen',
    fr: 'Veuillez remplir autres',
    it: 'Per favore compila gli altri il resto',
    hi: 'कृपया दूसरे भी हों तो भरें',
    ta: 'வேறு ஏதுமிருப்பின் தயவுசெய்து பிற என்று நிரப்பவும்',
    te: 'దయచేసి ఇతరులను ఏవైనా పూరించండి',
    ml: 'മറ്റെന്തെങ്കിലും ഉണ്ടെങ്കില്‍ പൂരിപ്പിക്കുക',
    kn: 'ಬೇರೆ ಯಾವುದಾದರೂ ಇದ್ದಲ್ಲಿ ದಯವಿಟ್ಟು ಭರ್ತಿ ಮಾಡಿ',
    mr: 'कृपया कोणतेही असल्यास इतरांना भरा',
    gu: 'જો હોય તો, કૃપા કરીને અન્ય વિગત ભરો',
    bn: 'দয়া করে অন্যন্য শূন্যস্থানও পূরণ করুন, যদি কিছু থাকে',
    pa: 'ਕਿਰਪਾ ਕਰਕੇ ਦੂਜਾ ਭਰੋ ਜੇਕਰ ਕੋਈ ਹੋਵੇ',
    bh: 'कृपया दूसरे भी हों तो भरें',
    id: 'Silakan isi lain-lain',
    ar: 'يرجى ترك اجابات اخرى إن وجدت',
    tr: 'Başka varsa lütfen doldurunuz',
    my: 'Sila isi dalam lain-lain jika ada',
    Russian: 'Укажите другие, если таковые имеются',
    Spanish: 'Por favor rellena otras si es posible',
    Thai: 'กรุณากรอกข้อมูลอื่น ๆ',
    Vietnamese: 'Hãy điền câu trả lời khác',
    Filipino: 'Pakipunan ang iba'
  },
  'ShortText.phoneNumber.wrong': {
    cn: '请填写正确的手机号码',
    tc: '請填寫正確的手機號碼',
    en: 'Enter your phone number',
    ja: '電話番号（携帯）を記入してください',
    ko: '올바른 휴대폰 번호를 입력해 주세요.',
    pt: 'Favor preencher com um número de telefone válido',
    de: 'Bitte eine korrekte Telefonnummer eingeben',
    fr: 'Veuillez entrer un n° de portable correct',
    it: 'Per favore inserisci un numero di telefono corretto',
    hi: 'कृपया सही फोन नंबर डालें',
    ta: 'சரியான தொலைபேசி எண்ணை நிரப்பவும்',
    te: 'దయచేసి సరైన ఫోన్ నంబర్ని పూరించండి',
    ml: 'ശരിയായ ഫോൺ നമ്പർ പൂരിപ്പിക്കുക',
    kn: 'ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಭರ್ತಿ ಮಾಡಿ',
    mr: 'कृपया एक बरोबर फोन नंबर भरा',
    gu: 'કૃપા કરીને સાચો ફોન નંબર નાખો',
    bn: 'দয়া করে সঠিক ফোন নম্বর দিন',
    pa: 'ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਫੋਨ ਨੰਬਰ ਭਰੋ',
    bh: 'कृपया सही फोन नंबर डालें',
    id: 'Silakan isi nomor telpon',
    ar: 'يرجى ترك رقم الهاتف الصحيح',
    tr: 'Lütfen doğru bir telefon numarası doldurunuz',
    my: 'Sila isi nombor telefon yang betul',
    Russian: 'Укажите действующий номер мобильного телефона',
    Spanish: 'Por favor rellena un número de teléfono correcto',
    Thai: 'กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง',
    Vietnamese: 'Hãy điền số điện thoại chính xác',
    Filipino: 'Pakipuno ng tamang phone number'
  },
  'MultiChoice.min.or.max': {
    // min max 不要翻译啊 ${min} ${max} 是一个整体 不要动
    cn: function cn(min, max) {
      return min && max ? "\u9700\u8981\u9009\u62E9\u81F3\u5C11".concat(min, "\u4E2A\u6700\u591A").concat(max, "\u4E2A\u9009\u9879") : min ? "\u81F3\u5C11\u9009\u62E9".concat(min, "\u4E2A\u9009\u9879") : "\u6700\u591A\u9009\u62E9".concat(max, "\u4E2A\u9009\u9879");
    },
    tc: function tc(min, max) {
      return min && max ? "\u9700\u8981\u9078\u64C7\u81F3\u5C11".concat(min, "\u500B\u6700\u591A").concat(max, "\u500B\u9078\u9805") : min ? "\u81F3\u5C11\u9078\u64C7".concat(min, "\u500B\u9078\u9805") : "\u6700\u591A\u9078\u64C7".concat(max, "\u500B\u9078\u9805");
    },
    en: function en(min, max) {
      return "Please select ".concat(min, " ~ ").concat(max, " options");
    },
    ja: function ja(min, max) {
      return "".concat(min, " ~ ").concat(max, " \u500B\u3067\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044");
    },
    ko: function ko(min, max) {
      return " ".concat(min, " ~ ").concat(max, "\uAC1C \uC0AC\uC774\uB85C \uC120\uD0DD\uC9C0\uB97C \uC120\uD0DD\uD574 \uC8FC\uC138\uC694.");
    },
    pt: function pt(min, max) {
      return "Favor selecionar no m\xE1ximo ".concat(max, " op\xE7\xF5es");
    },
    de: function de(min, max) {
      return "Bitte w\xE4hlen Sie ".concat(min, " ~ ").concat(max, " Optionen");
    },
    fr: function fr(min, max) {
      return "Veuillez s\xE9lectionner ".concat(min, " ~ ").concat(max, " options");
    },
    it: function it(min, max) {
      return "Per favore seleziona ".concat(min, " ~ ").concat(max, " opzioni");
    },
    hi: function hi(min, max) {
      return "\u0915\u0943\u092A\u092F\u093E ".concat(min, " ~ ").concat(max, " \u0911\u092A\u094D\u0936\u0928 \u091A\u0941\u0928\u0947\u0902");
    },
    ta: function ta(min, max) {
      return "".concat(min, " ~ ").concat(max, " \u0BB5\u0BBF\u0BB0\u0BC1\u0BAA\u0BCD\u0BAA\u0B99\u0BCD\u0B95\u0BB3\u0BC8\u0BA4\u0BCD \u0BA4\u0BC7\u0BB0\u0BCD\u0BA8\u0BCD\u0BA4\u0BC6\u0B9F\u0BC1\u0B95\u0BCD\u0B95\u0BB5\u0BC1\u0BAE\u0BCD");
    },
    te: function te(min, max) {
      return "\u0C26\u0C2F\u0C1A\u0C47\u0C38\u0C3F ".concat(min, " ~ ").concat(max, " \u0C0E\u0C02\u0C2A\u0C3F\u0C15\u0C32\u0C28\u0C41 \u0C0E\u0C02\u0C1A\u0C41\u0C15\u0C4B\u0C02\u0C21\u0C3F");
    },
    ml: function ml(min, max) {
      return "\u0D26\u0D2F\u0D35\u0D3E\u0D2F\u0D3F ".concat(min, " ~ ").concat(max, "\u0D13\u0D2A\u0D4D\u0D37\u0D28\u0D41\u0D15\u0D33\u0D4D\u200D \u0D24\u0D46\u0D30\u0D1E\u0D4D\u0D1E\u0D46\u0D1F\u0D41\u0D15\u0D4D\u0D15\u0D41\u0D15");
    },
    kn: function kn(min, max) {
      return "\u0CA6\u0CAF\u0CB5\u0CBF\u0C9F\u0CCD\u0C9F\u0CC1 ".concat(min, " ~ ").concat(max, " \u0C86\u0CAF\u0CCD\u0C95\u0CC6\u0C97\u0CB3\u0CA8\u0CCD\u0CA8\u0CC1 \u0C86\u0CAF\u0CCD\u0C95\u0CC6\u0CAE\u0CBE\u0CA1\u0CBF");
    },
    mr: function mr(min, max) {
      return "\u0915\u0943\u092A\u092F\u093E ".concat(min, " ~ ").concat(max, " \u092A\u0930\u094D\u092F\u093E\u092F \u0928\u093F\u0935\u0921\u093E");
    },
    gu: function gu(min, max) {
      return "\u0A95\u0AC3\u0AAA\u0ABE \u0A95\u0AB0\u0AC0\u0AA8\u0AC7 ".concat(min, " ~ ").concat(max, " \u0AB5\u0ABF\u0A95\u0AB2\u0ACD\u0AAA\u0ACB \u0AAA\u0AB8\u0A82\u0AA6 \u0A95\u0AB0\u0ACB");
    },
    bn: function bn(min, max) {
      return "\u09A6\u09AF\u09BC\u09BE \u0995\u09B0\u09C7 ".concat(min, " ~ ").concat(max, " \u09AC\u09BF\u0995\u09B2\u09CD\u09AA \u09AC\u09BE\u099B\u09C1\u09A8");
    },
    pa: function pa(min, max) {
      return "\u0A15\u0A3F\u0A30\u0A2A\u0A3E \u0A15\u0A30\u0A15\u0A47 ".concat(min, " ~ ").concat(max, " \u0A35\u0A3F\u0A15\u0A32\u0A2A\u0A3E\u0A02 \u0A26\u0A40 \u0A1A\u0A4B\u0A23 \u0A15\u0A30\u0A4B");
    },
    bh: function bh(min, max) {
      return "\u0915\u0943\u092A\u092F\u093E ".concat(min, " ~ ").concat(max, " \u0911\u092A\u094D\u0936\u0928 \u091A\u0941\u0928\u0947\u0902");
    },
    id: function id(min, max) {
      return "Diharuskan pilih minimum ".concat(min, " dan maksimum ").concat(max);
    },
    ar: function ar(min, max) {
      return "\u064A\u0631\u062C\u064A \u0627\u062E\u062A\u064A\u0627\u0631 ".concat(min, " ~ ").concat(max);
    },
    tr: function tr(min, max) {
      return "L\xFCtfen ".concat(min, " ~ ").concat(max, " aras\u0131 se\xE7iniz");
    },
    my: function my(min, max) {
      return "Sila guna pilihan ".concat(min, " ~ ").concat(max);
    },
    Russian: function Russian(min, max) {
      return "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B ".concat(min, " ~ ").concat(max);
    },
    Spanish: function Spanish(min, max) {
      return "Por favor elige ".concat(min, " ~ ").concat(max, " de opciones");
    },
    Thai: function Thai(min, max) {
      return "\u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E15\u0E31\u0E27\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E21\u0E32\u0E01\u0E01\u0E27\u0E48\u0E32 ".concat(min, " \u0E19\u0E49\u0E2D\u0E22\u0E01\u0E27\u0E48\u0E32 ").concat(max, " \u0E15\u0E31\u0E27\u0E40\u0E25\u0E37\u0E2D\u0E01");
    },
    Vietnamese: function Vietnamese(min, max) {
      return "H\xE3y ch\u1ECDn t\u1EEB ".concat(min, " ~ ").concat(max, " \u0111\xE1p \xE1n");
    },
    Filipino: function Filipino(min, max) {
      return "Kailangang pumili mula sa ".concat(min, " hanggang sa ").concat(max);
    }
  },
  'ShortText.overflow': {
    cn: '字数已超50',
    tc: '字數已經超過50',
    en: 'Character limit: 50 characters',
    ja: '50文字以内で入力してくだい',
    ko: '이미 50자를 초과했습니다.',
    pt: 'Texto excede 50 palavras',
    de: 'Die 50-Wörter-Grenze wurde überschritten',
    fr: 'Votre message dépasse les 50 mots',
    it: 'Supera 50 parole',
    hi: '50 शब्दों से ज्यादा हो गए हैं',
    ta: 'நீளம் 50 வார்த்தைகளை மீறுகிறது',
    te: 'పొడవు 50 పదాలను మించిపోయింది',
    ml: 'അക്ഷര പരിധി 50 വാക്കുകള്‍ കവിഞ്ഞു',
    kn: 'ಪದಗಳ ಮಿತಿ 50 ಮೀರಿದೆ',
    mr: 'लांबी 50 शब्द ओलांडली आहे',
    gu: 'વિગત 50 શબ્દો કરતા વધારે છે',
    bn: '৫০ শব্দের বেশি হয়ে গিয়েছে',
    pa: 'ਲੰਬਾਈ 50 ਸ਼ਬਦਾਂ ਤੋਂ ਵੱਧ ਹੈ',
    bh: '50 शब्दों से ज्यादा हो गए हैं',
    id: 'Melebihi 50 huruf',
    ar: 'تم تجاوز 50 رمزا',
    tr: 'Uzunluk 50 kelimeyi aşıyor',
    my: 'Panjang melebihi 50 patah perkataan',
    Russian: 'Количество слов превысило 50',
    Spanish: 'Longitud excede 50 palabras',
    Thai: 'ความยาวเกิน 50 คำ',
    Vietnamese: 'Độ dài quá 50 ký tự',
    Filipino: 'Ang bilang ng salita ay lampas sa 50'
  },
  'ShortText.placeholder': {
    cn: '内容长度请控制在50字以内',
    tc: '內容長度請控制在50字以內',
    en: 'Character limit: 50 characters',
    ja: '50文字まで入力できます',
    ko: '50자 이내로 작성해 주세요',
    pt: 'Favor manter o limite de 50 palavras',
    de: 'Bitte die 50-Wörter-Grenze nicht überschreiten',
    fr: 'La limite autorisée est de 50 mots maximum',
    it: 'Per favore resta entro le 50 parole',
    hi: 'कृपया 50 शब्दों की सीमा में लिखें',
    ta: 'நீளம் 50 வார்த்தைகளுக்குள் இருக்கவேண்டும்',
    te: 'దయచేసి పొడవు 50 పదాలలో ఉంచండి',
    ml: 'ദയവായി അക്ഷര പരിധി 50 വാക്കിനുള്ളില്‍ നിലനിര്‍ത്തുക',
    kn: 'ಪದಗಳ ಮಿತಿ 50 ಮೀರಿದಂತೆ ನೋಡಿಕೊಳ್ಳಿ',
    mr: 'कृपया 50 शब्दांच्या आत लांबी ठेवा',
    gu: 'કૃપા કરીને વિગત 50 શબ્દોની અંદર રાખો',
    bn: 'দয়া করে ৫০০ শব্দের মধ্যে লিখুন',
    pa: 'ਕਿਰਪਾ ਕਰਕੇ ਲੰਬਾਈ 50 ਸ਼ਬਦਾਂ ਦੇ ਅੰਦਰ ਰੱਖੋ',
    bh: 'कृपया 50 शब्दों की सीमा में लिखें',
    id: 'Pastikan tidak melebihi 50 huruf',
    ar: 'يرجى عدم تجاوز 50 رمزا',
    tr: 'Lütfen uzunluğu 50 kelime içinde tutunuz',
    my: 'Sila pastikan panjang tidak melebihi 50 patah perkataan',
    Russian: 'Пожалуйста, придерживайтесь длины не более 50 слов',
    Spanish: 'Por favor mantén la longitud a 50 palabras',
    Thai: 'โปรดรักษาความยาวภายใน 50 คำ',
    Vietnamese: 'Độ dài câu trả lời không quá 50 ký tự',
    Filipino: 'Huwag lumampas sa 50 salita'
  },
  'LongText.placeholder': {
    cn: '内容长度请控制在2000字以内',
    tc: '內容長度請控制在2000字以內',
    en: 'Character limit: 2000 characters',
    ja: '2000文字まで入力できます',
    ko: '2000자 이내로 작성해 주세요',
    pt: 'Favor manter o limite de 2000 palavras',
    de: 'Bitte die 2000-Wörter-Grenze nicht überschreiten',
    fr: 'La limite autorisée est de 2000 mots maximum',
    it: 'Per favore resta entro le 2000 parole',
    hi: 'कृपया 2000 शब्दों की सीमा में लिखें',
    ta: 'நீளம் 2000 வார்த்தைகளுக்குள் இருக்கவேண்டும்',
    te: 'దయచేసి పొడవు 2000 పదాలలో ఉంచండి',
    ml: 'ദയവായി അക്ഷര പരിധി 2000 വാക്കിനുള്ളില്‍ നിലനിര്‍ത്തുക',
    kn: 'ಪದಗಳ ಮಿತಿ 2000 ಮೀರಿದಂತೆ ನೋಡಿಕೊಳ್ಳಿ',
    mr: 'कृपया 2000 शब्दांच्या आत लांबी ठेवा',
    gu: 'કૃપા કરીને વિગત 2000 શબ્દોની અંદર રાખો',
    bn: 'দয়া করে ৫০০ শব্দের মধ্যে লিখুন',
    pa: 'ਕਿਰਪਾ ਕਰਕੇ ਲੰਬਾਈ 2000 ਸ਼ਬਦਾਂ ਦੇ ਅੰਦਰ ਰੱਖੋ',
    bh: 'कृपया 2000 शब्दों की सीमा में लिखें',
    id: 'Pastikan tidak melebihi 2000 huruf',
    ar: 'يرجى عدم تجاوز 2000 رمزا',
    tr: 'Lütfen uzunluğu 2000 kelime içinde tutunuz',
    my: 'Sila pastikan panjang tidak melebihi 2000 patah perkataan',
    Russian: 'Пожалуйста, придерживайтесь длины не более 2000 слов',
    Spanish: 'Por favor mantén la longitud a 2000 palabras',
    Thai: 'โปรดรักษาความยาวภายใน 2000 คำ',
    Vietnamese: 'Độ dài câu trả lời không quá 2000 ký tự',
    Filipino: 'Huwag lumampas sa 2000 salita'
  },
  'LocChoice.placeholder': {
    cn: '选择省市区',
    tc: '選擇省市區',
    en: 'Select your state',
    ja: '都道府県を選択してください',
    ko: '도/시/구를 선택해 주세요.',
    pt: 'Selecione sua localização',
    de: 'Standort auswählen',
    fr: 'Choisissez votre ville',
    it: 'Seleziona la tua posizione',
    hi: 'अपनी लोकेशन चुनें',
    ta: 'உங்கள் இருப்பிடத்தைத் தேர்ந்தெடுக்கவும்',
    te: 'మీ స్థానాన్ని ఎంచుకోండి',
    ml: 'നിങ്ങളുടെ ലൊക്കേഷന്‍ തിരഞ്ഞെടുക്കുക',
    kn: 'ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
    mr: 'आपले स्थान निवडा',
    gu: 'તમારું લોકેશન પસંદ કરો',
    bn: 'আপনার অবস্থান সিলেক্ট করুন',
    pa: 'ਆਪਣਾ ਲੋਕੇਸ਼ਨ ਚੁਣੋ',
    bh: 'अपनी लोकेशन चुनें',
    id: 'Pilih Kota',
    ar: 'حدد موقعك',
    tr: 'Konumunuzu Seçin',
    my: 'Pilih lokasi anda',
    Russian: 'Укажите вашу локацию',
    Spanish: 'Selecciona tu ubicación',
    Thai: 'เลือกตำแหน่งของคุณ',
    Vietnamese: 'Chọn tỉnh/thành phố của bạn',
    Filipino: 'Pilihin ang lakasyon'
  },
  'LocChoice.placeholder.hideDistrict': {
    cn: '选择省市',
    tc: '選擇省市',
    en: 'Select your city',
    ja: '市区町村を選択してください',
    ko: '도/시를 선택해 주세요.',
    pt: 'Selecione sua localização',
    de: 'Standort auswählen',
    fr: 'Choisissez votre ville',
    it: 'Seleziona la tua posizione',
    hi: 'अपनी लोकेशन चुनें',
    ta: 'உங்கள் இருப்பிடத்தைத் தேர்ந்தெடுக்கவும்',
    te: 'మీ స్థానాన్ని ఎంచుకోండి',
    ml: 'നിങ്ങളുടെ ലൊക്കേഷന്‍ തിരഞ്ഞെടുക്കുക',
    kn: 'ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
    mr: 'आपले स्थान निवडा',
    gu: 'તમારું લોકેશન પસંદ કરો',
    bn: 'আপনার অবস্থান সিলেক্ট করুন',
    pa: 'ਆਪਣਾ ਲੋਕੇਸ਼ਨ ਚੁਣੋ',
    bh: 'अपनी लोकेशन चुनें',
    id: 'Pilih Provinsi',
    ar: 'حدد موقعك',
    tr: 'Konumunuzu Seçin',
    my: 'Pilih lokasi anda',
    Russian: 'Укажите вашу локацию',
    Spanish: 'Selecciona tu ubicación',
    Thai: 'เลือกตำแหน่งของคุณ',
    Vietnamese: 'Chọn thành phố/thị xã của bạn',
    Filipino: 'Pilihin ang lakasyon'
  },
  'Submit.success': {
    cn: '提交成功',
    tc: '提交成功',
    en: 'Submitted',
    ja: '送信完了',
    ko: '제출 성공',
    pt: 'Sucesso',
    de: 'Erfolgreich abgegeben',
    fr: 'Succès',
    it: 'Successo',
    hi: 'सफल',
    ta: 'வெற்றி',
    te: 'విజయం',
    ml: 'വിജയകരം',
    kn: 'ಯಶಸ್ಸು',
    mr: 'यशस्वी',
    gu: 'સફળતા',
    bn: 'সফল',
    pa: 'ਸਫਲ',
    bh: 'सफल',
    id: 'Sukses',
    ar: 'تمت العملية بنجاح',
    tr: 'Başarılı',
    my: 'Berjaya',
    Russian: 'Успешно отправлено',
    Spanish: 'Listo',
    Thai: 'สำเร็จ',
    Vietnamese: 'Gửi thành công',
    Filipino: 'Tagumpay'
  },
  'Submit.thanks': {
    cn: '感谢你的参与!',
    tc: '感謝你的參與!',
    en: 'Thank you for your participation!',
    ja: 'ご協力ありがとうございました',
    ko: '참여해주셔서 감사합니다.',
    pt: 'Obrigado pela sua participação!',
    de: 'Vielen Dank für Ihre Teilnahme!',
    fr: 'Merci pour votre participation !',
    it: 'Grazie per aver partecipato!',
    hi: 'भाग लेने के लिए धन्यवाद',
    ta: 'தங்கள் பங்கேற்புக்கு நன்றி!',
    te: 'మీ భాగస్వామ్యానికి ధన్యవాదాలు!',
    ml: 'നിങ്ങളുടെ പങ്കാളിത്തത്തിന് നന്ദി!',
    kn: 'ನಿಮ್ಮ ಪಾಲ್ಗೊಳ್ಳುವಿಕೆಗೆ ಧನ್ಯವಾದಗಳು!',
    mr: 'आपल्या सहभागाबद्दल धन्यवाद!',
    gu: 'તમારા પાર્ટીસિપેશન બદલ આભાર',
    bn: 'অংশ নেওয়ার জন্য ধন্যবাদ',
    pa: 'ਤੁਹਾਡੀ ਭਾਗੀਦਾਰੀ ਲਈ ਧੰਨਵਾਦ!',
    bh: 'भाग लेने के लिए धन्यवाद',
    id: 'Terima kasih telah berpartisipasi!',
    ar: 'نشكركم على المشاركة!',
    tr: 'Katıldığınız için teşekkür ederiz!',
    my: 'Terima kasih di atas penyertaan anda!',
    Russian: 'Благодарим за участие!',
    Spanish: '¡Gracias por tu participación!',
    Thai: 'ขอบคุณสำหรับการเข้าร่วม!',
    Vietnamese: 'Cảm ơn bạn đã tham gia!',
    Filipino: 'Salamat sa iyong pagsali'
  },
  'no.id': {
    cn: '找不到问卷ID',
    tc: '找不到問卷ID',
    en: 'The questionnaire ID could not be found',
    ja: 'アンケートIDが見つかりません',
    ko: '설문지 ID를 찾을 수 없습니다.',
    pt: 'A ID do questionário não foi encontrada',
    de: 'Die Fragebogen-ID kann nicht gefunden werden',
    fr: 'Ce questionnaire est introuvable',
    it: "L'ID del questionario non è stato trovato",
    hi: 'इस प्रश्नसूचि की आईडी नहीं मिल रही',
    ta: 'கேள்வி எண்களை கண்டுபிடிக்க முடியவில்லை',
    te: 'ప్రశ్నాపత్ర ID కనుగొనబడలేదు',
    ml: 'ചോദ്യാവലിയുടെ ഐഡി കണ്ടെത്താനായില്ല',
    kn: 'ಪ್ರಶ್ನಾವಳಿ ID ಅನ್ನು ಕಂಡುಹಿಡಿಯಲಾಗಲಿಲ್ಲ',
    mr: 'प्रश्नावली आयडी सापडला नाही',
    gu: 'પ્રશ્નાવલિ ID મળી શકાયો નથી',
    bn: 'প্রশ্নাবলীর আইডি খুঁজে পাওয়া যাচ্ছে না',
    pa: 'ਪ੍ਰਸ਼ਨਾਵਲੀ ਆਈਡੀ ਨਹੀਂ ਲੱਭਿਆ ਜਾ ਸਕਿਆ',
    bh: 'इस प्रश्नसूचि की आईडी नहीं मिल रही',
    id: 'Tidak ditemukan survey ID',
    ar: 'تعذر العثور على معرّف الاستبيان',
    tr: 'Anket numarası bulunamadı',
    my: 'ID soal selidik tidak dapat ditemui',
    Russian: 'Номер анкеты не найден',
    Spanish: 'El ID de cuestionario no pudo ser encontrada',
    Thai: 'ไม่พบรหัสของแบบสอบถาม',
    Vietnamese: 'Không thể tìm thấy ID bảng khảo sát',
    Filipino: 'Hindi nahanap ang ID ng questionnaire'
  },
  'get.questionarie.error': {
    cn: '获取问卷失败',
    tc: '獲取問卷失敗',
    en: "Couldn't load questionnaire",
    ja: 'アンケートの読込みに失敗しました',
    ko: '설문지 가져오기에 실패했습니다.',
    pt: 'Falha em obter questionário',
    de: 'Der Fragebogen kann nicht abgerufen werden',
    fr: "Impossible d'accéder au questionnaire",
    it: 'Caricamento del questionario non riuscito',
    hi: 'प्रश्नसूचि नहीं मिल रही',
    ta: 'கேள்விகளை பெற முடியவில்லை',
    te: 'ప్రశ్నావళిని పొందడంలో విఫలమైంది',
    ml: 'ചോദ്യാവലി ലഭിക്കുന്നില്ല',
    kn: 'ಪ್ರಶ್ನಾವಳಿ ಪಡೆಯಲು ವಿಫಲವಾಗಿದೆ',
    mr: 'प्रश्नावली मिळविण्यात अयशस्वी',
    gu: 'પ્રશ્નાવલિ મેળવવા માટે નિષ્ફળતા',
    bn: 'প্রশ্নাবলী পাওয়া যাচ্ছে না',
    pa: 'ਪ੍ਰਸ਼ਨਾਵਲੀ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਅਸਫਲ',
    bh: 'प्रश्नसूचि नहीं मिल रही',
    id: 'Gagal menampilkan survey',
    ar: 'فشل الحصول على الاستبيان',
    tr: 'Anket Alınamadı',
    my: 'Gagal mendapatkan soal selidik',
    Russian: 'Не удалось получить анкету',
    Spanish: 'Error al obtener el cuestionario',
    Thai: 'การรับแบบสอบถามล้มเหลว',
    Vietnamese: 'Không thể lấy bảng khảo sát',
    Filipino: 'Nabigong kunin ang questionnaire'
  },
  'close.before.submit': {
    cn: '表单未提交，确认要关闭么？',
    tc: '表單未提交，確認要關閉嗎?',
    en: 'The form has not been submitted. Close the survey anyway?',
    ja: 'アンケートの内容はまだ送信されていません。本当に終了しますか。',
    ko: '설문조사가 완성되지 않았습니다. 정말 닫으시겠습니까?',
    pt: 'O formulário não foi enviado. Deseja sair assim mesmo?',
    de: 'Das Formular wurde nicht abgegeben. Möchten Sie es trotzdem schließen?',
    fr: "Le formulaire n'a pas été pas validé. Êtes-vous sûr(e) de vouloir quitter ?",
    it: 'Il modulo non è stato inviato. Sei sicuro di interrompere?',
    hi: 'फॉर्म अभी भरा नहीं है। क्या आप छोड़ना चाहते हैं?',
    ta: 'படிவம் சமர்ப்பிக்கப்படவில்லை. நீங்கள் நிச்சயமாக வெளியேற வேண்டுமா?',
    te: 'ఫోరమ్ సమర్పించబడలేదు. మీరు ఖచ్చితంగా నిష్క్రమించాలనుకుంటున్నారా?',
    ml: 'ഫോം ഇതുവരെ സമര്‍പ്പിച്ചിട്ടില്ല. നിങ്ങള്‍ ക്വിറ്റ്ചെയ്യുന്ന കാര്യം ഉറപ്പിച്ചോ?',
    kn: 'ಫಾರ್ಮ್ ಅನ್ನು ಸಲ್ಲಿಸಲಾಗುತ್ತಿಲ್ಲ. ನೀವು ನಿರ್ಗಮಿಸಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?',
    mr: 'फॉर्म सबमिट केला गेला नाही. आपण बाहेर पडायचे आहे का?',
    gu: 'ફોર્મ સબમિટ કરવામાં આવ્યું નથી. શું તમે બહાર નીકળવા માંગો છો?',
    bn: 'ফর্ম সাবমিট করা হয় নি।আপনি নিশ্চিত যে আপনি বেরিয়ে যেতে চান?',
    pa: 'ਫਾਰਮ ਨੂੰ ਜਮ੍ਹਾ ਨਹੀਂ ਕੀਤਾ ਗਿਆ ਹੈ। ਕੀ ਤੁਸੀਂ ਬੰਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?',
    bh: 'फॉर्म अभी भरा नहीं है। क्या आप छोड़ना चाहते हैं?',
    id: 'Form belum terkirim, yakin ingin keluar halaman ini?',
    ar: 'لم يتم إرسال النموذج. هل تريد تاكيد المغادرة؟',
    tr: 'Form gönderilmedi. Çıkmak istediğinizden emin misiniz?',
    my: 'Borang belum diserah, anda pasti ingin berhenti?',
    Russian: 'Анкета не отправлена. Вы уверены, что хотите закрыть?',
    Spanish: 'La encuesta no ha sido enviada. ¿Estás seguro que deseas salir?',
    Thai: 'คุณยังไม่ได้ส่งแบบฟอร์ม คุณยืนยันที่จะยกเลิกหรือไม่?',
    Vietnamese: 'Câu trả lời của bạn chưa được gửi đi. Bạn có chắc mình muốn đóng bảng khảo sát?',
    Filipino: 'Hindi pa isinumite ang questionnaire, sigurado ka bang tumigil?'
  },
  'mobile.selected': {
    cn: '请选择',
    tc: '請選擇',
    en: 'Please select',
    ja: '選択してください',
    ko: '선택하세요.',
    pt: 'Favor selecionar',
    de: 'Bitte wählen',
    fr: 'Veuillez sélectionner',
    it: 'Per favore seleziona',
    hi: 'कृपया चुनें',
    ta: 'தயவு செய்து தேர்வு செய்யவும்',
    te: 'దయచేసి ఎంచుకోండి',
    ml: 'ദയവായി തിരഞ്ഞെടുക്കുക',
    kn: 'ದಯವಿಟ್ಟು ಆಯ್ಕೆ ಮಾಡಿ',
    mr: 'कृपया निवडा',
    gu: 'મહેરબાની કરી, પસંદ કરો',
    bn: 'দয়া করে বেছে নিন',
    pa: 'ਕਿਰਪਾ ਕਰਕੇ ਚੁਣੋ',
    bh: 'कृपया चुनें',
    id: 'Silakan pilih',
    ar: 'يرجى الاختيار',
    tr: 'Lütfen seçiniz',
    my: 'Sila pilih',
    Russian: 'Пожалуйста, выберите',
    Spanish: 'Por favor selecciona',
    Thai: 'โปรดเลือก',
    Vietnamese: 'Hãy chọn',
    Filipino: 'Pakipili'
  },
  'error.forbidden': {
    cn: '你不在此次定向调研名单中',
    en: 'You are not on the list of this targeted research'
  },
  'error.submitted': {
    cn: '你已经回答过该问卷了, 感谢你的参与',
    tc: '你已經回答過該問卷了，感謝你的參與',
    en: "You've completed the questionnaire. Thank you very much for your cooperation.",
    ja: 'アンケートにご回答いただき誠にありがとうございました。',
    ko: '본 설문조사에 이미 참여하신 적이 있습니다. 참여해 주셔서 감사합니다.',
    pt: 'Você completou o questionário com sucesso. Obrigado pela sua cooperação.',
    de: 'Sie haben den Fragebogen erfolgreich ausgefüllt. Vielen Dank für Ihre Zusammenarbeit!',
    fr: 'Vous avez répondu au questionnaire. Merci pour votre participation.',
    it: 'Hai completato il questionario. Grazie mille per la collaborazione.',
    hi: 'आपने प्रश्नावली पूरी कर ली है। आपके सहयोग के लिए बहुत-बहुत धन्यवाद।',
    ta: 'நீங்கள் கேள்வித்தாளை முடித்துவிட்டீர்கள். உங்களது ஒத்துழைப்பிற்கு நன்றி. ',
    te: 'మీరు ప్రశ్నాపత్రాన్ని పూర్తి చేసారు. మీ సహకారానికి చాల ధన్యవాధాలు.',
    ml: 'നിങ്ങൾ ചോദ്യാവലി പൂർത്തിയാക്കിയിരിക്കുന്നു. നിങ്ങളുടെ സഹകരണത്തിന് നന്ദി.',
    kn: 'ನೀವು ಪ್ರಶ್ನಾವಳಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದೀರಿ. ನಿಮ್ಮ ಸಹಕಾರಕ್ಕಾಗಿ ತುಂಬಾ ಧನ್ಯವಾದಗಳು.',
    mr: 'आपण प्रश्नावली पूर्ण केली आहे. आपल्या सहकार्याबद्दल आपले खूप आभार.',
    gu: 'તમે પ્રશ્નાવલી પૂર્ણ કરી છે. આપના સહયોગ અંગે ખૂબ ખૂબ આભાર',
    bn: 'প্রশ্নোত্তর পর্ব শেষ হয়ে গিয়েছে। আপনার সহযোগিতার জন্য ধন্যবাদ',
    pa: 'ਤੁਸੀਂ ਪ੍ਰਸ਼ਨਾਵਲੀ ਪੂਰੀ ਕਰ ਲਈ ਹੈ। ਤੁਹਾਡੇ ਸਹਿਯੋਗ ਲਈ ਬਹੁਤ ਧੰਨਵਾਦ। ',
    bh: 'आपने प्रश्नावली पूरी कर ली है। आपके सहयोग के लिए बहुत-बहुत धन्यवाद।',
    id: 'Anda telah mengisi survey ini, terima kasih.',
    ar: 'لقد أكملتم الاستبيان شكرا جزيلا على تعاونكم.',
    tr: 'Anketi tamamladınız. Yardımınız için teşekkür ederiz.',
    my: 'Anda telah melengkapkan soal selidik ini. Terima kasih banyak di atas kerjasama anda',
    Russian: 'Вы успешно ответили на все вопросы, благодарим за участие!',
    Spanish: 'Haz completado el cuestionario. Muchas gracias por tu cooperación.',
    Thai: 'คุณตอบแบบสอบถามสำเร็จแล้ว ขอขอบคุณสำหรับความร่วมมือของคุณ!',
    Vietnamese: 'Bạn đã hoàn thành bảng khảo sát. Cảm ơn bạn đã tham gia!',
    Filipino: 'Natapos mo ang questionnaire na ito, salamat sa iyong pagsali.'
  },
  'error.expired': {
    cn: '该问卷已过期, 感谢你的参与',
    tc: '該問卷已經過期，感謝你的參與',
    en: 'This questionnaire has expired. Thanks for your participation. ',
    ja: 'アンケート回答期限が過ぎております。',
    ko: '본 설문조사는 이미 만기되었습니다. 참여해 주셔서 감사합니다.',
    pt: 'Este questionário expirou. Obrigado pela sua participação.',
    de: 'Die Zeit für diesen Fragebogen ist abgelaufen. Vielen Dank für Ihre Teilnahme. ',
    fr: "Ce questionnaire n'est plus disponible Merci pour votre participation.",
    it: 'Questo questionario è scaduto. Grazie per la tua partecipazone. ',
    hi: 'यह प्रश्नावली समाप्त हो गई है। इसमें भाग लेने के लिए धन्यवाद।',
    ta: 'இந்த கேள்வித்தாள் காலாவதியாகிவிட்டது. இதில் பங்கேற்றதற்கு நன்றி. ',
    te: 'ఈ ప్రశ్నాపత్రం గడువు ముగిసింది. మీ భాగస్వామ్యంకు  ధన్యవాదాలు.',
    ml: 'ഈ ചോദ്യാവലിയുടെ കാലാവധി കഴിഞ്ഞു. പങ്കെടുത്തതിന് നന്ദി.',
    kn: 'ಈ ಪ್ರಶ್ನಾವಳಿ ಅವಧಿ ಮೀರಿದೆ. ನಿಮ್ಮ ಭಾಗವಹಿಸುವಿಕೆಗೆ ಧನ್ಯವಾದಗಳು.',
    mr: 'ही प्रश्नावली कालबाह्य झाली आहे. आपल्या सहभागाबद्दल धन्यवाद.',
    gu: 'આ પ્રશ્નાવલી પૂર્ણ થઇ ગઇ છે. આમાં ભાગ લેવા બદલ આપનો ખૂબ ખૂબ આભાર',
    bn: 'প্রশ্নাবলি সমাপ্ত হয়ে গিয়েছে। অংশগ্রহনের জন্য ধন্যবাদ',
    pa: 'ਇਸ ਪ੍ਰਸ਼ਨਾਵਲੀ ਦੀ ਆਖ਼ਿਰੀ ਤਰੀਕ ਖਤਮ ਹੋ ਗਈ ਹੈ. ਤੁਹਾਡੀ ਭਾਗੀਦਾਰੀ ਲਈ ਧੰਨਵਾਦ। ',
    bh: 'यह प्रश्नावली समाप्त हो गई है। इसमें भाग लेने के लिए धन्यवाद।',
    id: 'Survey ini telah tidak aktif, terima kasih.',
    ar: 'انتهت مدة صلاحية هذا الاستبيان. شكرا ً على المشاركة ',
    tr: 'Bu anketin süresi doldu. Katılımınız için teşekkür ederiz.',
    my: 'Soal selidik ini telah tamat tempoh. Terima kasih di atas penyertaan anda.',
    Russian: 'Срок действия анкеты истек, благодарим за участие!',
    Spanish: 'El cuestionario ha expirado. Gracias por tu participación',
    Thai: 'แบบสอบถามนี้หมดอายุแล้ว ขอขอบคุณสำหรับการเข้าร่วมของคุณ!',
    Vietnamese: 'Bảng khảo sát đã hết hiệu lực. Cảm ơn bạn đã tham gia!',
    Filipino: 'Lumipas na ang questionnaire na ito, salamat sa iyong pagsali.'
  },
  'error.close': {
    cn: '该问卷已关闭, 感谢你的参与',
    tc: '問卷已關閉, 感謝你的參與',
    en: 'This questionnaire has been closed. Thanks for your participation. ',
    ja: 'このアンケートは終了しました',
    ko: '본 설문지는 마감되었습니다',
    pt: 'Este questionário foi fechado',
    de: 'Dieser Fragebogen wurde geschlossen',
    fr: 'Ce questionnaire a été fermé',
    it: 'Questo questionario è stato chiuso',
    hi: 'यह प्रश्नावली बंद कर दी गई है',
    ta: 'இந்தக் கேள்வித்தாள் மூடப்பட்டுள்ளது',
    te: 'ఈ ప్రశ్నాపత్రం మూసివేయబడింది',
    ml: 'ഈ ചോദ്യാവലി അടച്ചു',
    kn: 'ಈ ಪ್ರಶ್ನಾವಳಿಯನ್ನು ಮುಚ್ಚಲಾಗಿದೆ',
    mr: 'ही प्रश्नावली बंद करण्यात आली आहे',
    gu: 'આ પ્રશ્નાવલી બંધ કરવામાં આવી છે',
    bn: 'এই প্রশ্নাবলী বন্ধ করা হয়েছে',
    pa: 'ਇਹ ਪ੍ਰਸ਼ਨਾਵਲੀ ਬੰਦ ਕਰ ਦਿੱਤੀ ਗਈ ਹੈ',
    bh: 'यह प्रश्नावली बंद कर दी गई है',
    id: 'Kuesioner ini telah ditutup',
    ar: 'لقد تم إغلاق هذا الاستبيان',
    tr: 'Bu anket kapatıldı',
    my: 'Soal selidik ini telah ditutup',
    Russian: 'Эта анкета закрыта',
    Spanish: 'Este cuestionario ha sido cerrado',
    Thai: 'แบบสอบถามนี้ถูกปิดแล้ว',
    Vietnamese: 'Bảng câu hỏi này đã bị đóng',
    Filipino: 'Ang questionnaire na ito ay sarado na'
  },
  'error.nonexistence': {
    cn: '该问卷不存在, 感谢你的参与',
    tc: '該問卷不存在，感謝你的參與',
    en: "The questionnaire doesn't exist.",
    ja: '本アンケートは存在しません。',
    ko: '해당 설문조사는 존재하지 않습니다. 참여해 주셔서 감사합니다.',
    pt: 'Este questionário não existe. Obrigado pela visita.',
    de: 'Dieser Fragebogen ist nicht mehr online. Vielen Dank dennoch für Ihr Interesse.',
    fr: "Ce questionnaire n'est plus en ligne. Merci de votre visite.",
    it: 'Questo questionario non esiste. Grazie per la tua partecipazone. ',
    hi: 'कोई प्रश्नावली नहीं मिली। जाँच के लिए धन्यवाद।',
    ta: 'இந்த கேள்வித்தாள் தற்போது இல்லை. உங்களது வருகைக்கு நன்றி.',
    te: 'ప్రశ్నాపత్రం కనబడలేదు. మీరు చెక్ చేసినందుకు ధన్యవాదాలు.',
    ml: 'ഈ ചോദ്യാവലി ഇപ്പോൾ നിലവിലില്ല. സന്ദർശിച്ചതിന് നന്ദി.',
    kn: 'ಪ್ರಶ್ನಾವಳಿ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ. ನಿಮ್ಮ ಭೇಟಿಗೆ ಧನ್ಯವಾದಗಳು.',
    mr: 'ही प्रश्नावली आता अस्तित्वात नाही. आपल्या भेटीसाठी धन्यवाद.',
    gu: 'કોઇ પ્રશ્નાવલી મળી નથી. તપાસ બદલ ધન્યવાદ',
    bn: 'কোনও প্রশ্ন পাওয়া যাচ্ছে না। সন্ধান করার জন্য ধন্যবাদ',
    pa: 'ਪ੍ਰਸ਼ਨਾਵਲੀ ਮੌਜੂਦ ਨਹੀਂ ਹੈ, ਤੁਹਾਡੇ ਆਉਣ ਲਈ ਧੰਨਵਾਦ।',
    bh: 'कोई प्रश्नावली नहीं मिली। जाँच के लिए धन्यवाद।',
    id: 'Survey tidak ditemukan, terima kasih.',
    ar: 'الاستبيان غير موجود. شكراً لزيارتك',
    tr: 'Anket bulunamadı. Ziyaretiniz için teşekkür ederiz.',
    my: 'Soal selidik ini tidak wujud. Terima kasih di atas kunjungan anda.',
    Russian: 'Анкета не существует, благодарим за участие!',
    Spanish: 'El cuestionario no existe. Gracias por su visita',
    Thai: 'ไม่มีแบบสอบถาม ขอบคุณสำหรับการเยี่ยมชม',
    Vietnamese: 'Bảng khảo sát không tồn tại. Cảm ơn bạn đã ghé thăm.',
    Filipino: 'Hindi umiiral ang questionnaire na ito, salamat sa iyong pagsali'
  },
  'error.server_error': {
    cn: '服务异常',
    tc: '服務異常',
    en: "The service isn't available.",
    ja: 'サービスエラー',
    ko: '서버 오류',
    pt: 'Este serviço não está disponível.',
    de: 'Der Service ist nicht verfügbar.',
    fr: "Ce service n'est pas disponible.",
    it: 'Il servizio non è disponibile.',
    hi: 'सेवा अनुपलब्ध है।',
    ta: 'இந்த சேவை தற்போது பயன்பாட்டில் இல்லை ',
    te: 'సేవ అందుబాటులో లేదు.',
    ml: 'ഈ സേവനം ഇപ്പോൾ ലഭ്യമല്ല.',
    kn: 'ಸೇವೆ ಲಭ್ಯವಿಲ್ಲ.',
    mr: 'ही सेवा उपलब्ध नाही.',
    gu: 'સેવા ઉપલબ્ધ છે',
    bn: 'পরিষেবা উপলব্ধ নেই',
    pa: 'ਸੇਵਾ ਉਪਲਬਧ ਨਹੀਂ ਹੈ',
    bh: 'सेवा अनुपलब्ध है।',
    id: 'Servis eror',
    ar: 'الخدمة غير متوفرة.',
    tr: 'Servis mevcut değil.',
    my: 'Perkhidmatan tidak tersedia.',
    Russian: 'Сервис недоступен',
    Spanish: 'El servicio no se encuentra disponible.',
    Thai: 'ไม่สามารถใช้บริการได้',
    Vietnamese: 'Dịch vụ không khả dụng.',
    Filipino: 'Hindi available ang serbisyong ito.'
  },
  back: {
    cn: '返回',
    en: 'Back',
    Vietnamese: 'Làm lại'
  },
  option: {
    cn: '选项',
    en: 'Option'
  },
  'option-analysis': {
    cn: '选项解析',
    en: 'Option analysis',
    Vietnamese: 'Giải thích'
  },
  'correct-answer': {
    cn: '正确答案',
    en: 'Correct answer',
    Vietnamese: 'Đáp án'
  },
  'your-answer': {
    cn: '你的答案',
    en: 'Your answer',
    Vietnamese: 'Trả lời'
  },
  'this-question-score': {
    cn: '本题得分',
    en: 'Question score',
    Vietnamese: 'Điểm'
  },
  'answer-analysis': {
    cn: '答案解析',
    en: 'Answer analysis',
    Vietnamese: 'Kiểm tra đáp án'
  },
  'try-again': {
    cn: '再考一次',
    en: 'Take the test again.',
    Vietnamese: 'Làm lại để ghi 100 điểm nào!'
  },
  'show-analysis': {
    cn: '点击查看答案解析',
    en: 'Click to view answer analysis.',
    Vietnamese: 'Xem đáp án đúng'
  },
  'to-lottery': {
    cn: '点击此处去抽奖',
    tc: '點擊此處去抽獎',
    en: 'Join the lucky draw now!',
    ja: '抽選へ',
    ko: '상품 추첨에 참가해 보십시오.',
    pt: 'Junte-se ao sorteio agora!',
    de: 'Nehmen Sie jetzt an der Verlosung teil! ',
    fr: 'Participez à notre tirage au sort !',
    it: "Partecipa all'estrazione ora!",
    hi: 'अब लकी ड्रा में शामिल हों!',
    ta: 'உடனே இந்த அதிருஷ்ட குலுக்கலில் இணையுங்கள் ',
    te: 'ఇప్పుడు లక్కీ డ్రాలో చేరండి!',
    ml: 'ലക്കി ഡ്രോയിൽ ഇപ്പോൾ പങ്കെടുക്കൂ!',
    kn: 'ಲಕ್ಕಿ ಡ್ರಾದಲ್ಲಿ ಈಗಲೇ ಭಾಗವಹಿಸಿ!',
    mr: 'आता लकी ड्रॉमध्ये सामील व्हा!',
    gu: 'હવે આપ લકી ડ્રોમાં સામેલ થાઓ',
    bn: 'লাকি ড্র-এ যোগ দিন',
    pa: 'ਹੁਣੇ ਲੱਕੀ ਡਰਾਅ ਨਾਲ ਜੁੜੋ!',
    bh: 'अब लकी ड्रा में शामिल हों!',
    id: 'Ikut undian!',
    ar: 'الانضمام إلى القرعة الان',
    tr: 'Şanslı çekilişe şimdi katılın!',
    my: 'Sertai cabutan bertuah sekarang!',
    Russian: 'Участвовать в розыгрыше',
    Spanish: '¡Únete al sorteo ahora!',
    Thai: 'เข้าร่วมการจับรางวัลตอนนี้!',
    Vietnamese: 'Tham gia ngay rút thăm may mắn!',
    Filipino: 'Sumali sa lucky draw ngayon!'
  },
  'user-score': {
    cn: '你的得分',
    tc: '你的得分',
    en: 'Your score',
    ja: 'スコア',
    ko: '나의 점수',
    pt: 'Seus pontos',
    de: 'Ihre Punktzahl',
    fr: 'Votre score',
    it: 'Il tuo punteggio',
    hi: 'आपका स्कोर',
    ta: 'உங்களது மதிப்பெண் ',
    te: 'మీ స్కోర్',
    ml: 'നിങ്ങളുടെ സ്‌കോർ',
    kn: 'ನಿಮ್ಮ ಸ್ಕೋರ್',
    mr: 'आपले गुण',
    gu: 'તમારો સ્કોર',
    bn: 'আপনার স্কোর',
    pa: 'ਤੁਹਾਡਾ ਸਕੋਰ',
    bh: 'आपका स्कोर',
    id: 'Score anda',
    ar: 'نقاطك',
    tr: 'Skorunuz',
    my: 'Skor anda',
    Russian: 'Ваши баллы',
    Spanish: 'Tu puntaje',
    Thai: 'คะแนนของคุณ',
    Vietnamese: 'Điểm của bạn',
    Filipino: 'score mo'
  },
  'number-only': {
    cn: '请填写数字',
    tc: '請填寫數字',
    en: 'Enter a number',
    ja: '数字を入力してください',
    ko: '숫자를 입력해 주십시오.',
    pt: 'Adicione um número por favor.',
    de: 'Bitte geben Sie eine Nummer an.',
    fr: 'Veuillez entrer un numéro.',
    it: 'Per favore inserisci un numero',
    hi: 'कृपया एक नंबर लिखें.',
    ta: 'ஏதாவது ஒரு எண்ணை இடவும் ',
    te: 'దయచేసి నంబర్ను వ్రాయండి.',
    ml: 'ഒരു നമ്പർ നൽകൂ.',
    kn: 'ದಯವಿಟ್ಟು ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ',
    mr: 'कृपया नंबर टाका.',
    gu: 'કૃપા કરી એક નંબર લખો',
    bn: 'দয়া করে নম্বর লিখুন',
    pa: 'ਕਿਰਪਾ ਕਰਕੇ ਇਕ ਨੰਬਰ ਲਿਖੋ। ',
    bh: 'कृपया एक नंबर लिखें.',
    id: 'Silakan masukkan nomor.',
    ar: 'ضع رقماً من فضلك',
    tr: 'Lütfen bir numara yazın',
    my: 'Sila masukkan nombor.',
    Russian: 'Пожалуйста, заполните цифру',
    Spanish: 'Por favor introduce un número',
    Thai: 'โปรดกรอกตัวเลข',
    Vietnamese: 'Hãy nhập số điện thoại.',
    Filipino: 'Pakipunan ng bilang.'
  },
  'error.type_not_supported': {
    cn: '不支持该文件类型',
    tc: '不支持該文件類型',
    en: 'The file type is not supported',
    Russian: 'Этот тип файла не поддерживается',
    Spanish: 'Este tipo de archivo no es aceptado',
    Thai: 'ไม่รองรับประเภทไฟล์',
    Vietnamese: 'Không hỗ trợ loại tệp này',
    Filipino: 'Hindi suporta ang file type na ito'
  },
  'optionInput.specificGravity': {
    cn: '请调整比重',
    tc: '請調整比重',
    en: 'Please adjust the weight'
  },
  language: {
    cn: '问卷语言',
    tc: '問卷語言',
    en: 'Language',
    ja: '言語',
    ko: '언어',
    pt: 'linguagem',
    de: 'Sprache',
    fr: 'langue',
    it: 'lingua',
    hi: 'भाषा',
    ta: 'மொழி',
    te: 'భాష',
    ml: 'ഭാഷ',
    kn: 'ಭಾಷೆ',
    mr: 'इंग्रजी',
    gu: 'ભાષા',
    bn: 'ভাষা',
    pa: 'ਭਾਸ਼ਾ',
    bh: 'भाखा',
    id: 'bahasa',
    ar: 'لغة الاستبيان',
    tr: 'Anket dili',
    my: 'bahasa',
    Russian: 'язык',
    Spanish: 'idioma',
    Thai: 'ภาษา',
    Vietnamese: 'ngôn ngữ',
    Filipino: 'wika'
  }
};