var theme = {
  normalDark: {
    primaryColor: '#ffa200 !important',
    textColor: '#AA8866 !important',
    textColorSecondary: '#6c553a !important',
    questionBg: '#272727 !important',
    antTableHover: 'rgba(49, 94, 251, 0.09) !important',
    antMenuHover: 'rgba(49, 94, 251, 0.03) !important',
    antBg: 'transparent !important',
    antBorder: '#AA8866 !important',
    amEntityBg: 'rgba(255,255,255,0.1) !important',
    antEntityBg: '#272727 !important',
    antEntityBorder: '#AA8866 !important',
    antTableBorder: '#6c553a !important',
    scaleSquare: '#272727 !important',
    backgroundColor: '#000000 !important',
    inputBg: 'transparent !important',
    endingBg: '#000000 !important',
    btnText: '#272727 !important',
    sliderThumb: '#272727 !important',
    checkboxDisabledColor: '#62625a !important',
    titleStickyBg: '#272727 !important'
  },
  classicalDark: {
    primaryColor: '#f49e32 !important',
    textColor: '#d6ddf2 !important',
    textColorSecondary: '#7d86a2 !important',
    questionBg: '#3a4461 !important',
    antTableHover: 'rgba(49, 94, 251, 0.09) !important',
    antMenuHover: 'rgba(49, 94, 251, 0.03) !important',
    antBg: 'transparent !important',
    antBorder: '#d6ddf2 !important',
    antEntityBg: '#3a4461 !important',
    amEntityBg: 'rgba(255,255,255,0.1) !important',
    antEntityBorder: '#d6ddf2 !important',
    antTableBorder: '#7d86a2 !important',
    scaleSquare: '#3a4461 !important',
    backgroundColor: '#253050 !important',
    inputBg: 'rgba(255,255,255,0.1) !important',
    endingBg: '#253050 !important',
    btnText: '#253050 !important',
    sliderThumb: '#3a4461 !important',
    checkboxDisabledColor: '#758b98 !important',
    titleStickyBg: '#3a4361 !important'
  },
  technology: {
    primaryColor: '#f49e32 !important',
    textColor: '#d6ddf2 !important',
    textColorSecondary: '#727783 !important',
    questionBg: 'linear-gradient(#1f0d62, #513220) !important',
    antTableHover: 'rgba(49, 94, 251, 0.09) !important',
    antMenuHover: 'rgba(49, 94, 251, 0.03) !important',
    antBg: 'transparent !important',
    antBorder: '#727783 !important',
    antEntityBg: 'linear-gradient(#1f0d62, #513220) !important',
    amEntityBg: 'rgba(255,255,255,0.1) !important',
    antEntityBorder: '#727783 !important',
    antTableBorder: '#727783 !important',
    scaleSquare: 'rgba(255,255,255,0.1) !important',
    backgroundColor: '#000000 !important',
    antCheckBg: '#1f0d62 !important',
    inputBg: 'rgba(255,255,255,0.1) !important',
    endingBg: 'linear-gradient(#1f0d62, #513220) !important',
    sliderThumb: 'linear-gradient(#1f0d62, #513220) !important',
    checkboxDisabledColor: '#684e84 !important',
    titleStickyBg: 'linear-gradient(#1f0d62, #513220) !important'
  },
  linearGreen: {
    primaryColor: '#fab921 !important',
    textColor: '#fdfff0 !important',
    textColorSecondary: '#d4e4c3 !important',
    questionBg: 'rgba(0,0,100,0.1) !important',
    antTableHover: 'rgba(49, 94, 251, 0.09) !important',
    antMenuHover: 'rgba(49, 94, 251, 0.03) !important',
    antBg: 'transparent !important',
    antBorder: '#d4e4c3 !important',
    antEntityBg: '#6e948c !important',
    amEntityBg: 'rgba(255,255,255,0.1) !important',
    antEntityBorder: '#d4e4c3 !important',
    antTableBorder: '#d4e4c3 !important',
    scaleSquare: 'rgba(255,255,255,0.1) !important',
    antCheckBg: '#577e80 !important',
    backgroundColor: 'linear-gradient(#577e80, #85bb7f) !important',
    inputBg: 'rgba(255,255,255,0.1) !important',
    endingBg: 'linear-gradient(#577e80, #85bb7f) !important',
    btnText: '#577e80 !important',
    checkboxDisabledColor: '#a7c9c3 !important',
    titleStickyBg: '#688b8d !important',
    mobileFooterBg: '#85bb7f !important'
  },
  linearPurple: {
    primaryColor: '#21fae7 !important',
    textColor: '#fff !important',
    textColorSecondary: '#c19ac9 !important',
    questionBg: 'rgba(255,255,255,0.1) !important',
    antTableHover: 'rgba(49, 94, 251, 0.09) !important',
    antMenuHover: 'rgba(49, 94, 251, 0.03) !important',
    antBg: 'transparent !important',
    antBorder: '#c19ac9 !important',
    antEntityBg: 'linear-gradient(#3b0d62, #67228e) !important',
    amEntityBg: 'rgba(255,255,255,0.1) !important',
    antEntityBorder: '#c19ac9 !important',
    antTableBorder: '#c19ac9 !important',
    scaleSquare: 'rgba(255,255,255,0.1) !important',
    antCheckBg: '#3b0d62 !important',
    backgroundColor: 'linear-gradient(#3b0d62, #67228e) !important',
    inputBg: 'rgba(255,255,255,0.1) !important',
    endingBg: 'linear-gradient(#3b0d62, #67228e) !important',
    btnText: '#3b0d62 !important',
    sliderThumb: 'linear-gradient(#3b0d62, #67228e) !important',
    checkboxDisabledColor: '#927ca7 !important',
    titleStickyBg: '#5f2e83 !important',
    mobileFooterBg: '#67228e !important'
  },
  chLight: {
    primaryColor: '#a9cd4e !important',
    textColor: '#3c8e86 !important',
    textColorSecondary: '#9bb9b5 !important',
    questionBg: '#fbfaf5 !important',
    antTableHover: 'rgba(49, 94, 251, 0.09) !important',
    antMenuHover: 'rgba(49, 94, 251, 0.03) !important',
    antBg: 'rgba(255,255,255,0.1) !important',
    antBorder: '#9bb9b5 !important',
    antEntityBg: '#fbfaf5 !important',
    amEntityBg: 'rgba(239, 238, 234, 0.8) !important',
    antEntityBorder: '#9bb9b5 !important',
    antTableBorder: '#9bb9b5 !important',
    scaleSquare: 'rgba(255,255,255,0.1) !important',
    backgroundColor: '#efeeea !important',
    inputBg: '#fbfaf5 !important',
    endingBg: '#fbfaf5 !important',
    btnText: '#fbfaf5 !important',
    sliderThumb: '#fbfaf5 !important',
    checkboxDisabledColor: '#c7c7c7 !important',
    titleStickyBg: '#fbfbf7 !important'
  },
  lightWarm: {
    primaryColor: '#ffab15 !important',
    textColor: '#593c2b !important',
    textColorSecondary: '#aaa198 !important',
    questionBg: '#fff !important',
    antTableHover: 'rgba(49, 94, 251, 0.09) !important',
    antMenuHover: 'rgba(49, 94, 251, 0.03) !important',
    antBg: 'rgba(255,255,255,0.1) !important',
    antBorder: '#aaa198 !important',
    antEntityBg: '#fff !important',
    amEntityBg: 'rgb(252, 243, 226, 0.6)  !important',
    antEntityBorder: '#aaa198 !important',
    antTableBorder: '#aaa198 !important',
    scaleSquare: 'rgba(255,255,255,0.1) !important',
    backgroundColor: '#fcf3e2 !important',
    inputBg: 'transparent !important',
    endingBg: '#fcf3e2 !important',
    btnText: '#fff !important',
    sliderThumb: '#fff !important',
    checkboxDisabledColor: '#c7c7c7 !important',
    titleStickyBg: '#fff !important'
  },
  lightCold: {
    primaryColor: '#6490e5 !important',
    textColor: '#444951 !important',
    textColorSecondary: '#b6c1cd !important',
    questionBg: '#fff !important',
    antTableHover: 'rgba(49, 94, 251, 0.09) !important',
    antMenuHover: 'rgba(49, 94, 251, 0.03) !important',
    antBg: 'rgba(255,255,255,0.1) !important',
    antBorder: '#b6c1cd !important',
    antEntityBg: '#fff !important',
    amEntityBg: 'rgb(231, 236, 242, 0.6) !important',
    antEntityBorder: '#b6c1cd !important',
    antTableBorder: '#b6c1cd !important',
    scaleSquare: 'rgba(255,255,255,0.1) !important',
    backgroundColor: '#e7ecf2 !important',
    inputBg: 'rgba(255,255,255,0.1) !important',
    endingBg: '#e7ecf2 !important',
    btnText: '#fff !important',
    sliderThumb: '#fff !important',
    checkboxDisabledColor: '#c7c7c7 !important',
    titleStickyBg: '#fff !important'
  },
  cyberpunk: {
    primaryColor: '#2ad3bd !important',
    textColor: '#e7eeff !important',
    textColorSecondary: '#a1afcf !important',
    questionBg: '#2f4a5c !important',
    antTableHover: 'rgba(49, 94, 251, 0.09) !important',
    antMenuHover: 'rgba(49, 94, 251, 0.03) !important',
    antBg: 'transparent !important',
    antBorder: '#a1afcf !important',
    antEntityBg: '#2f4a5c !important',
    amEntityBg: 'rgb(231, 236, 242, 0.1) !important',
    antEntityBorder: '#a1afcf !important',
    antTableBorder: '#a1afcf !important',
    scaleSquare: 'rgba(255,255,255,0.1) !important',
    backgroundColor: '#243e4d !important',
    inputBg: 'rgba(255,255,255,0.1) !important',
    endingBg: '#243e4d !important',
    btnText: '#2f4a5c !important',
    sliderThumb: '#2f4a5c !important',
    checkboxDisabledColor: '#94b7e3 !important',
    titleStickyBg: '#2f4a5c !important'
  }
};
export default theme;