export var postMessage = function postMessage(data) {
  try {
    var _window$parent, _window$opener;
    if ((_window$parent = window.parent) === null || _window$parent === void 0 ? void 0 : _window$parent.postMessage) {
      console.debug('post message', data);
      try {
        window.parent.postMessage(data, '*');
      } catch (error) {
        console.error('parent postMessage error:', error.message);
      }
    }
    if ((_window$opener = window.opener) === null || _window$opener === void 0 ? void 0 : _window$opener.postMessage) {
      console.debug('post message', data);
      try {
        window.opener.postMessage(data, '*');
      } catch (error) {
        console.error('opener postMessage error:', error.message);
      }
    }
  } catch (err) {
    console.error(err);
  }
};