import _objectSpread from "@babel/runtime/helpers/objectSpread";
import Tea from '@dp/byted-tea-sdk-oversea';
import { debounce } from 'throttle-debounce';
import { checkUndefined } from '@/utilities/common';
Tea.init({
  app_id: 1482,
  channel: 'va',
  channel_type: 'ttp',
  disable_auto_pv: true
});
Tea.config({
  log: !process.env.NODE_ENV.includes('production'),
  _staging_flag: process.env.NODE_ENV.includes('production') ? 0 : 1
});
if (!window.isLocal && !window.isRD) {
  Tea.start();
}
var prevPageTitle = '';
export var tracePageOpen = debounce(150, function () {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var pageTitle = title;
  if (prevPageTitle === pageTitle) {
    return;
  }
  // 改变页面title
  // 发送pageview事件。
  if (!window.isLocal && !window.isRD) {
    Tea.predefinePageView({
      title: pageTitle,
      url: window.location.href
    });
  }
  prevPageTitle = pageTitle;
});
var getConf = function getConf() {
  var _window = window,
    teaConf = _window.teaConf;
  if (!teaConf || !checkUndefined(teaConf)) return {};
  return window.teaConf;
};
export var traceShowQuestion = function traceShowQuestion(sum) {
  if (window.isLocal || window.isRD || typeof sum !== 'number') return;
  console.log('Tea traceShowQuestion');
  Tea.event('show_questionnaire', _objectSpread({}, getConf(), {
    sum_question: sum
  }));
};
export var traceUpdateQuestion = debounce(300, function (type, question_order, question_id) {
  if (window.isLocal || window.isRD) return;
  Tea.event('update_questionnaire', _objectSpread({}, getConf(), {
    reply_type: type ? 1 : 0,
    question_order: question_order,
    question_id: question_id
  }));
});
export var traceSubmitQuestionnaire = function traceSubmitQuestionnaire(isSubmit) {
  if (window.isLocal || window.isRD) return;
  Tea.event('submit_questionaire', _objectSpread({}, getConf(), {
    submit_questionnaire: isSubmit ? 1 : 0
  }));
};
export var traceClickAdv = function traceClickAdv() {
  if (window.isLocal || window.isRD) return;
  Tea.event('click_advertisement', _objectSpread({}, getConf(), {
    url: window.location.href
  }));
};
export var traceExitQuestionnaire = function traceExitQuestionnaire(exit) {
  if (window.isLocal || window.isRD) return;
  Tea.event('exit_questionnaire', _objectSpread({}, getConf(), {
    exit: exit ? 1 : 0
  }));
};

// 报错时埋点
export var traceOnError = function traceOnError(error, code, errStack) {
  Tea.event('on_error_code', _objectSpread({}, getConf(), {
    error: error,
    error_stack: errStack,
    error_code: Number(code),
    url: window.location.href
  }));
};