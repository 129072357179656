import React from 'react';
import withPalette from './index';
import { useStyles } from '../../../common/Question/MaterialUI/materialTheme';
import Slider from '../../../common/Question/MaterialUI/MaterialSlider';
function MaterialSlider(props) {
  var classes = useStyles();
  return React.createElement("div", {
    style:
    // props.isInsideMatrix
    //   ? { width: '82%', marginLeft: '9%' }
    //   : { width: '90%', marginLeft: '5%' }
    {
      width: '82%',
      marginLeft: '9%'
    },
    className: classes.root
  }, React.createElement(Slider, props));
}
export default withPalette(MaterialSlider);