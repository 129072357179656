import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";
export var noop = function noop() {};
export var checkUndefined = function checkUndefined(obj) {
  return Object.values(obj).every(function (v) {
    return v !== undefined;
  });
};
export var toInt = function toInt(str) {
  var errRet = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (!str) return errRet;
  if (typeof str === 'number') return Math.round(str);
  if (typeof str !== 'string') {
    console.error("Notice, you are converting an ".concat(_typeof(str), " into a number, it's most probably a bug."));
    return errRet;
  }
  var ret = Number.parseInt(str, 10);
  if (Number.isNaN(ret)) return errRet;
  return ret;
};
export var CaseUtil = {
  // 把python服务器返回的snake风格的接口字段转化为caml风格
  toCaml: function toCaml(obj) {
    var _this = this;
    var upperFirst = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (!obj) return obj;
    var ret = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach(function (key) {
      var value = obj[key];
      var keyCaml = key.toLowerCase().replace(/_([a-z])/g, function (_, c) {
        return c.toUpperCase();
      });
      if (upperFirst) keyCaml = keyCaml.replace(/^[a-z]/, function (c) {
        return c.toUpperCase();
      });
      if (isObject(value)) ret[keyCaml] = _this.toCaml(value, upperFirst);else ret[keyCaml] = value;
    });
    return ret;
  },
  // 把js的Caml风格的接口字段转化为snake风格
  toSnake: function toSnake(obj) {
    var _this2 = this;
    if (!obj) return obj;
    var ret = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach(function (key) {
      var value = obj[key];
      var keyLine = key.replace(/[A-Z]/g, function (c, offset) {
        return "".concat(offset ? '_' : '').concat(c.toLowerCase());
      }).toLowerCase();
      if (isObject(value)) ret[keyLine] = _this2.toSnake(value);else ret[keyLine] = value;
    });
    return ret;
  }
};
export var isObject = function isObject(obj) {
  return obj && _typeof(obj) === 'object';
};
export var cloneDeep = function cloneDeep() {
  var src = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  for (var i = 0; i < (arguments.length <= 1 ? 0 : arguments.length - 1); i++) {
    var tar = i + 1 < 1 || arguments.length <= i + 1 ? undefined : arguments[i + 1];
    if (!isObject(tar)) continue;
    // bfs
    var Q = [];
    Q.push({
      src: src,
      tar: tar
    });
    while (Q.length) {
      var now = Q.shift();
      var keys = Object.keys(now.tar);
      for (var j = 0; j < keys.length; j++) {
        var key = keys[j];
        if (isObject(now.tar[key])) {
          now.src[key] = now.src[key] || Array.isArray(now.tar[key]) ? [] : {};
          Q.push({
            src: now.src[key],
            tar: now.tar[key]
          });
        } else {
          now.src[key] = now.tar[key];
        }
      }
    }
  }
  return src;
};
export var closest = function closest(el, selector) {
  var matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
  var copyEl = el;
  while (copyEl) {
    if (matchesSelector.call(copyEl, selector)) {
      return copyEl;
    }
    copyEl = copyEl.parentElement;
  }
  return null;
};
export var getRealName = function getRealName(_ref) {
  var question = _ref.question,
    answers = _ref.answers,
    questionType = _ref.questionType;
  var _split = (question.nameRef || '').split('--matrix--'),
    _split2 = _slicedToArray(_split, 2),
    nameRef = _split2[0],
    lineId = _split2[1];
  var realLineId = lineId;
  if (lineId && lineId.indexOf('title') > -1) {
    realLineId = lineId.slice(0, -6);
  }
  var _question$name = question.name,
    defaultName = _question$name === void 0 ? '' : _question$name,
    custom = question.custom;
  var multiLangName = question === null || question === void 0 ? void 0 : question["".concat(window.language, "Name")];
  if (!multiLangName) multiLangName = 'Question';
  var name = window.showMultiLang ? multiLangName : defaultName;
  var key = '{选项引用}';
  var key2 = '{xxxx}';
  var reg = new RegExp(key, 'g');
  var reg2 = new RegExp(key2, 'g');
  if ((name.includes(key) || name.includes(key2)) && nameRef && answers[nameRef]) {
    var refAns = answers[nameRef];
    if (refAns.type === 'scale') {
      name = name.replace(reg, refAns.degree).replace(reg2, refAns.degree);
    } else if (refAns.type === 'location') {
      var textArray = refAns.location || [];
      if (textArray.length && custom.nameRandom && custom.showNameOptionsNum) {
        textArray = randomShowOptions(textArray, custom.showNameOptionsNum, 'random');
      }
      name = name.replace(reg, textArray.join('/')).replace(reg2, textArray.join('/'));
    } else if (refAns.type === 'matrix') {
      var _textArray = [];
      ((refAns.selected || {})[realLineId] || []).forEach(function (selected) {
        if (selected === 'other') {
          var _refAns$text = refAns.text,
            text = _refAns$text === void 0 ? '其他' : _refAns$text;
          if (text.length > 20) {
            text = "\u5176\u4ED6:".concat(text.substring(0, 20), "...");
          } else {
            text = "\u5176\u4ED6:".concat(text);
          }
          _textArray.push(text);
        } else if (selected) {
          var targetQuestion = window.allQuestions.find(function (el) {
            return el.id === nameRef;
          });
          if (targetQuestion) {
            var targetOption = null;
            if (lineId && lineId.indexOf('title') > -1) {
              targetOption = targetQuestion.custom.lines.find(function (el) {
                return el.id === realLineId;
              });
            } else {
              targetOption = targetQuestion.custom.options.find(function (el) {
                return el.id === selected;
              });
            }
            if (targetOption) {
              var _targetOption;
              var selectedOption = window.showMultiLang ? (_targetOption = targetOption) === null || _targetOption === void 0 ? void 0 : _targetOption["".concat(window.language, "Text")] : targetOption.text;
              _textArray.push(selectedOption.replace(/<.*?>/gi, ''));
            }
          }
        }
        if (_textArray.length && custom.nameRandom && custom.showNameOptionsNum) {
          _textArray = randomShowOptions(_textArray, custom.showNameOptionsNum, 'random');
        }
      });
      name = name.replace(reg, _textArray.join(' / ')).replace(reg2, _textArray.join(' / '));
    } else {
      var _textArray2 = [];
      (refAns.selected || []).forEach(function (selected) {
        if (selected === 'other') {
          var _refAns$text2 = refAns.text,
            text = _refAns$text2 === void 0 ? '其他' : _refAns$text2;
          if (text.length > 20) {
            text = "\u5176\u4ED6:".concat(text.substring(0, 20), "...");
          } else {
            text = "\u5176\u4ED6:".concat(text);
          }
          _textArray2.push(text);
        } else if (selected) {
          var targetQuestion = window.allQuestions.find(function (el) {
            return el.id === nameRef;
          });
          if (targetQuestion) {
            var targetOption = targetQuestion.custom.realShowOptions.find(function (el) {
              return el.id === selected;
            });
            if (targetOption) {
              var selectedOption = window.showMultiLang ? targetOption === null || targetOption === void 0 ? void 0 : targetOption["".concat(window.language, "Text")] : targetOption.text;
              _textArray2.push(selectedOption.html2Text());
            }
          }
        }
      });
      if (_textArray2.length && custom.nameRandom && custom.showNameOptionsNum) {
        _textArray2 = randomShowOptions(_textArray2, custom.showNameOptionsNum, 'random');
      }
      name = name.replace(reg, _textArray2.join(' / ')).replace(reg2, _textArray2.join(' / '));
    }
  }
  return questionType ? name.replace(reg, '').replace(reg2, '').replace(/(.*)(<\/p>)/, "$1<span class=\"question-type\">".concat(questionType, "</span>$2")) : name.replace(reg, '').replace(reg2, '');
};
export var randomComp = function randomComp() {
  return Math.random() - 0.5;
};
export function randomArray(options) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'random';
  var saveNoRandomOptions = [];
  for (var i = options.length - 1; i >= 0; i--) {
    var option = options[i];
    if (option.noRandom) {
      saveNoRandomOptions.push([option, i]);
      options.splice(i, 1);
    }
  }
  var result = type === 'reverse' ? options.reverse() : options.slice().sort(randomComp);
  while (saveNoRandomOptions.length) {
    var _saveNoRandomOptions$ = saveNoRandomOptions.pop(),
      _saveNoRandomOptions$2 = _slicedToArray(_saveNoRandomOptions$, 2),
      _option = _saveNoRandomOptions$2[0],
      index = _saveNoRandomOptions$2[1];
    result.splice(index, 0, _option);
  }
  return result;
}

// 随机展示几个选项
export function randomShowOptions(options, length, type) {
  if (options.length && options.length > length) {
    var newOptions = _toConsumableArray(options);
    var newArr = []; // 组成的新数组初始化
    var _loop = function _loop(i) {
      var index = Math.floor(Math.random() * newOptions.length);
      var item = newOptions[index];
      if (type === 'reverse') {
        var optionIndex = 0;
        options.forEach(function (option, idx) {
          console.log(idx);
          if (option.id === item.id) {
            optionIndex = idx;
          }
        });
        newArr.push({
          option: item,
          index: optionIndex
        });
      } else {
        newArr.push(item);
      }
      newOptions.splice(index, 1);
    };
    for (var i = 0; i < length; i++) {
      _loop(i);
    }
    if (type === 'reverse') {
      newArr.sort(function (a, b) {
        return a.index - b.index;
      });
      newArr = newArr.map(function (item) {
        return item.option;
      });
    }
    return newArr;
  }
  return options;
}

// 返回string
export var getUrlParamsText = function getUrlParamsText() {
  var location = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _location$search = location.search,
    search = _location$search === void 0 ? '' : _location$search;
  var extra = filterXss(decodeURIComponent(window.location.search.substr(1)));
  return filterXss(decodeURIComponent(search)).concat(extra ? "&".concat(extra) : '');
};

// 返回对象
export var getUrlParams = function getUrlParams() {
  var location = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // 兼容hash后的自定义参数
  var _location$search2 = location.search,
    search = _location$search2 === void 0 ? '' : _location$search2;
  search = filterXss(search.substr(1));
  var extra = filterXss(decodeURIComponent(window.location.search.substr(1)));
  search = search.concat(extra ? "&".concat(extra) : '');
  var params = search.split('&');
  var customAnswer = {};
  params.forEach(function (str) {
    if (str.includes('=')) {
      var _str$split = str.split('='),
        _str$split2 = _slicedToArray(_str$split, 1),
        key = _str$split2[0];
      customAnswer[key] = str.substring(key.length + 1); // 从=号以后都是value, value有可能包含=号
    }
  });

  return customAnswer;
};
function filterXss(str) {
  // 去除包含<>内容的，防止xss漏洞
  var filterValue = str.replace(/<.*?>/g, '');
  // 去除<开头类型的xss漏洞
  filterValue = filterValue.replace(/<+.*$/g, '');
  return filterValue;
}
export var HTMLDecode = function HTMLDecode(text) {
  var _ref2, _temp, _temp2;
  var temp = document.createElement('div') || {};
  temp.innerHTML = text;
  var output = (_ref2 = ((_temp = temp) === null || _temp === void 0 ? void 0 : _temp.innerText) || ((_temp2 = temp) === null || _temp2 === void 0 ? void 0 : _temp2.textContent)) !== null && _ref2 !== void 0 ? _ref2 : '';
  temp = null;
  return output;
};
export function getPerformanceTiming() {
  var _window = window,
    performance = _window.performance;
  if (!performance) {
    return;
  }
  var t = performance.timing;
  var times = {};
  times.loadPage = t.loadEventEnd - t.navigationStart;
  times.domReady = t.domComplete - t.responseEnd;
  times.ttfb = t.responseStart - t.navigationStart;
  times.loadEvent = t.loadEventEnd - t.loadEventStart;
  return times;
}

// 比较版本号
export function compareVersion(version1, version2) {
  var arr1 = version1.split('.');
  var arr2 = version2.split('.');
  var length1 = arr1.length;
  var length2 = arr2.length;
  var minlength = Math.min(length1, length2);
  var i = 0;
  for (i; i < minlength; i++) {
    var a = parseInt(arr1[i]);
    var b = parseInt(arr2[i]);
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    }
  }
  if (length1 > length2) {
    for (var j = i; j < length1; j++) {
      if (parseInt(arr1[j]) != 0) {
        return 1;
      }
    }
    return 0;
  } else if (length1 < length2) {
    for (var _j = i; _j < length2; _j++) {
      if (parseInt(arr2[_j]) != 0) {
        return -1;
      }
    }
    return 0;
  }
  return 0;
}