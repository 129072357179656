import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from 'react';
import { PrettoSlider } from './materialTheme';
export default function MaterialSlider(_ref) {
  var onChange = _ref.onChange,
    selected = _ref.selected,
    min = _ref.min,
    max = _ref.max,
    id = _ref.id,
    _ref$isInsideMatrix = _ref.isInsideMatrix,
    isInsideMatrix = _ref$isInsideMatrix === void 0 ? false : _ref$isInsideMatrix,
    marks = _ref.marks,
    _ref$marksTop = _ref.marksTop,
    marksTop = _ref$marksTop === void 0 ? true : _ref$marksTop;
  var marksKeys = Object.keys(marks).sort(function (a, b) {
    return a - b;
  });
  var _useState = useState(+selected),
    _useState2 = _slicedToArray(_useState, 2),
    sliderVal = _useState2[0],
    setSliderVal = _useState2[1];
  var handleSubmit = function handleSubmit(event, newValue) {
    if (newValue !== +selected) {
      if (isInsideMatrix) {
        onChange(marksKeys[newValue - 1]);
      } else {
        onChange(newValue);
      }
    }
  };
  return React.createElement(PrettoSlider, {
    key: id,
    value: sliderVal,
    onChange: function onChange(e, newVal) {
      return setSliderVal(newVal);
    },
    onChangeCommitted: handleSubmit,
    step: 1,
    min: min,
    max: max,
    valueLabelDisplay: "on",
    valueLabelFormat: function valueLabelFormat(e) {
      return isNaN(e) ? '' : e;
    },
    marks: true,
    "aria-labelledby": "discrete-slider-restrict"
  });
}