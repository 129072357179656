import _message from "antd/es/message";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import isEmpty from 'lodash/isEmpty';
import shuffle from 'lodash/shuffle';
import sampleSize from 'lodash/sampleSize';
import { typeChecker, sampleGroups, getSampleGroupIds, getDepGroups, getGroupDepIds } from './quest';
import { getLocalText, navigatorLang } from './language';
import { getUrlParamsText, getUrlParams, CaseUtil } from './common';
export var setLang = function setLang(_ref) {
  var language = _ref.language,
    multiLanguage = _ref.multiLanguage,
    bilingual = _ref.bilingual,
    localLanguage = _ref.localLanguage,
    multiLngList = _ref.multiLngList;
  var lang = navigatorLang(language, multiLanguage, bilingual, localLanguage, multiLngList);
  window.language = lang;
  window.showMultiLang = bilingual && lang === 'en' || multiLanguage && window.language !== localLanguage;
};
export var handleLimitTime = function handleLimitTime(submitForm, basicLimit) {
  var clock = document.createElement('span');
  document.getElementById('limitTimeRef').appendChild(clock);
  var limitTime = basicLimit;
  window.limitTimeTask = setInterval(function () {
    if (limitTime === 0) {
      submitForm && submitForm();
      clearTimeout(window.limitTimeTask);
      return;
    }
    limitTime -= 1;
    var h = Math.floor(limitTime / 3600);
    if (h < 10) {
      h = "0".concat(h);
    }
    var m = Math.floor(limitTime % 3600 / 60);
    if (m < 10) {
      m = "0".concat(m);
    }
    var s = limitTime % 60;
    if (s < 10) {
      s = "0".concat(s);
    }
    clock.innerHTML = "".concat(h, ":").concat(m, ":").concat(s);
    if (limitTime < 60) {
      clock.style.color = 'red';
    }
  }, 1000);
};
export var transferDefaultDataToQuest = function transferDefaultDataToQuest(questionnaire) {
  // 获得 埋点信息
  window.teaConf = {
    deliver_id: questionnaire.deliverId || -1,
    survey_id: questionnaire.surveyId,
    uid: questionnaire.userId,
    questionnaire_id: questionnaire.questionnaireId,
    did: questionnaire.deviceId,
    app_id: questionnaire.appId
  };
  var quest = questionnaire.struct;
  quest.title = questionnaire.name;
  quest.titleHide = questionnaire.struct.titleHide;
  quest.desc = questionnaire.description;
  quest.customParams = questionnaire.customParams;
  quest.callbackAddr = questionnaire.callbackAddr;
  quest.quotaStrategy = questionnaire.quotaStrategy; // 选项配额
  quest.uid = questionnaire.userId;
  quest.did = questionnaire.deviceId;
  if (quest.bilingual) {
    quest.bilingual = false;
    quest.multiLanguage = true;
    quest.localLanguage = 'cn';
    quest.canSelectLanguage = true;
    quest.multiLngList = [{
      language: 'cn'
    }, {
      language: 'en'
    }];
  }

  // if (!quest.titleHide) {
  //   document.title = quest.title;
  // }
  var language = quest.language,
    multiLanguage = quest.multiLanguage,
    bilingual = quest.bilingual,
    localLanguage = quest.localLanguage,
    multiLngList = quest.multiLngList;
  setLang({
    language: language,
    multiLanguage: multiLanguage,
    bilingual: bilingual,
    localLanguage: localLanguage,
    multiLngList: multiLngList
  });
  var direction = (quest === null || quest === void 0 ? void 0 : quest.direction) || 'ltr';
  var rtl = direction === 'rtl';
  window.textDirection = {
    direction: direction,
    textAlign: rtl ? 'right' : 'start'
  };
  quest.questions.forEach(function (el, index) {
    var _el$dep;
    // optionFilter === null 的bug
    if (el.optionFilter === null) {
      delete el.optionFilter;
    }
    // 显示逻辑在后面的bug
    (((_el$dep = el.dep) === null || _el$dep === void 0 ? void 0 : _el$dep.cons) || []).map(function (con, i) {
      var conIndex = quest.questions.findIndex(function (q) {
        return q.id === con.id;
      });
      if (conIndex >= index) {
        el.dep.cons.splice(i, 1);
      }
      return con;
    });
  });
  return quest;
};

// 题目随机乱序逻辑
var shuffleQuestions = function shuffleQuestions(_ref2) {
  var questions = _ref2.questions;
  var newQuestions = [];
  var pageBreakIds = []; // 分页&描述题id
  var questionIds = [];
  questions.forEach(function (q, index) {
    if (['page_break', 'description'].includes(q.type)) {
      pageBreakIds.push(index);
    } else {
      questionIds.push(index);
    }
  });
  var qIds = shuffle(questionIds);
  for (var _i = 0; _i < pageBreakIds.length; _i++) {
    var idx = pageBreakIds[_i];
    qIds.splice(idx, 0, idx);
  }
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;
  try {
    for (var _iterator = qIds[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var _idx = _step.value;
      newQuestions.push(questions[_idx]);
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
  return newQuestions;
};

// 抽样逻辑
var sampleQuestions = function sampleQuestions(_ref3) {
  var questions = _ref3.questions,
    sampleCount = _ref3.sampleCount,
    _ref3$shuffle = _ref3.shuffle,
    shuffle = _ref3$shuffle === void 0 ? false : _ref3$shuffle,
    _ref3$groupIndex = _ref3.groupIndex,
    groupIndex = _ref3$groupIndex === void 0 ? null : _ref3$groupIndex,
    _ref3$sampleGroupInde = _ref3.sampleGroupIndex,
    sampleGroupIndex = _ref3$sampleGroupInde === void 0 ? null : _ref3$sampleGroupInde;
  var newQuestions = [];
  var pageBreakIds = [];
  var questionIds = [];
  questions.forEach(function (q, index) {
    if (['page_break', 'description'].includes(q.type)) {
      pageBreakIds.push(index);
    } else {
      questionIds.push(index);
    }
  });
  var qIds = sampleSize(questionIds, sampleCount);
  for (var _i2 = 0; _i2 < pageBreakIds.length; _i2++) {
    var idx = pageBreakIds[_i2];
    qIds.push(idx);
  }
  qIds.sort(function (a, b) {
    return a - b;
  });
  var _iteratorNormalCompletion2 = true;
  var _didIteratorError2 = false;
  var _iteratorError2 = undefined;
  try {
    for (var _iterator2 = qIds[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
      var _idx2 = _step2.value;
      newQuestions.push(_objectSpread({}, questions[_idx2], {
        group_index: groupIndex,
        sample_group_index: sampleGroupIndex
      }));
    }
  } catch (err) {
    _didIteratorError2 = true;
    _iteratorError2 = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
        _iterator2.return();
      }
    } finally {
      if (_didIteratorError2) {
        throw _iteratorError2;
      }
    }
  }
  if (shuffle) {
    newQuestions = shuffleQuestions({
      questions: newQuestions
    });
  }
  return newQuestions;
};

// 指定题目范围抽样逻辑
var sampleQuestionsByGroup = function sampleQuestionsByGroup(_ref4) {
  var questions = _ref4.questions,
    sampleConfig = _ref4.sampleConfig;
  var _sampleConfig$sample_ = sampleConfig.sample_by_question,
    sample_by_question = _sampleConfig$sample_ === void 0 ? [] : _sampleConfig$sample_,
    _sampleConfig$sample_2 = sampleConfig.sample_by_group,
    sample_by_group = _sampleConfig$sample_2 === void 0 ? [] : _sampleConfig$sample_2;
  var newQuestions = [];
  var pageBreakIds = [];
  var questionIds = [];
  questions.forEach(function (q, index) {
    if (['page_break', 'description'].includes(q.type)) {
      pageBreakIds.push(index);
    } else {
      questionIds.push(index);
    }
  });
  var groupDepIds = {}; // 有设置题目组显示逻辑
  var sampleGroupIds = {}; // 组随机出的题目组id

  // 题目分组 begin end改为索引
  var sampleQs = [];
  if (sample_by_question.length) {
    sample_by_question.forEach(function (q, idx) {
      var begin = questions.findIndex(function (item) {
        return item.id === q.begin_question;
      });
      var end = questions.findIndex(function (item) {
        return item.id === q.end_question;
      });
      sampleQs.push({
        begin: begin,
        end: end,
        sampleCount: q.sample_count,
        shuffle: q.shuffle
      });
    });
    groupDepIds = getGroupDepIds({
      sampleConfig: CaseUtil.toCaml(sampleConfig)
    });
  }
  var isShuffle = shuffleGroup({
    sampleConfig: CaseUtil.toCaml(sampleConfig)
  });

  // 如果随机组中没有配置显示依赖的题目组，则提前随机好题目
  if (sample_by_group.length && !isShuffle) {
    var _CaseUtil$toCaml = CaseUtil.toCaml(sampleConfig),
      sampleByQuestion = _CaseUtil$toCaml.sampleByQuestion;
    var depGroups = getDepGroups({
      sampleByQuestion: sampleByQuestion
    });
    if (Object.keys(groupDepIds).length === 0) {
      sampleGroupIds = sampleGroups({
        sampleConfig: CaseUtil.toCaml(sampleConfig)
      });
    } else {
      sample_by_group.forEach(function (groupConfig, index) {
        var _groupDepIds$index;
        if (!groupDepIds[index] || ((_groupDepIds$index = groupDepIds[index]) === null || _groupDepIds$index === void 0 ? void 0 : _groupDepIds$index.length) === 0) {
          sampleGroupIds[index] = getSampleGroupIds({
            depGroups: depGroups,
            groupConfig: CaseUtil.toCaml(groupConfig)
          });
        }
      });
    }
  }
  var start = 0;
  sampleQs.forEach(function (sample, groupIndex) {
    // 判断题目组有没有组随机
    var sampleGroupIndex = null;
    if (sample_by_group.length) {
      sampleGroupIndex = sample_by_group.findIndex(function (g) {
        return groupIndex >= g.begin_group && groupIndex <= g.end_group;
      });
    }
    var showQuest = sampleGroupIndex !== null && !isShuffle ? sampleGroupIds[sampleGroupIndex] ? sampleGroupIds[sampleGroupIndex].includes(groupIndex) : true : true;
    var begin = sample.begin,
      end = sample.end,
      sampleCount = sample.sampleCount,
      shuffle = sample.shuffle; // 题目没设置分组
    if (start < begin) {
      newQuestions = newQuestions.concat(questions.slice(start, begin));
    }
    start = end + 1;
    var questionList = showQuest ? sampleQuestions({
      questions: questions.slice(begin, end + 1),
      sampleCount: sampleCount,
      shuffle: shuffle,
      groupIndex: groupIndex,
      sampleGroupIndex: sampleGroupIndex
    }) : [];
    newQuestions = newQuestions.concat(questionList);
  });
  // 分组的题目最后的索引如果小于所有题目的长度，之后的题目都添加进去
  if (start < questions.length) {
    newQuestions = newQuestions.concat(questions.slice(start));
  }
  return newQuestions;
};
export var shuffleGroup = function shuffleGroup(_ref5) {
  var sampleConfig = _ref5.sampleConfig;
  var isShuffle = false;
  var _sampleConfig$sampleB = sampleConfig.sampleByGroup,
    sampleByGroup = _sampleConfig$sampleB === void 0 ? [] : _sampleConfig$sampleB;
  if (sampleByGroup.length) {
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;
    try {
      for (var _iterator3 = sampleByGroup[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var q = _step3.value;
        var _shuffle = q.shuffle;
        if (_shuffle) {
          isShuffle = true;
          break;
        }
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }
  }
  return isShuffle;
};
var showQuestion = function showQuestion(groupIds, groupIndex, sampleGroupIndex) {
  return groupIds.includes(groupIndex) || !Number.isInteger(sampleGroupIndex) || !Number.isInteger(groupIndex);
};

// 获取题目组随机后的所有显示题目
export var getRealQuestions = function getRealQuestions(_ref6) {
  var globalSampleGids = _ref6.globalSampleGids,
    questions = _ref6.questions;
  // 把随机出的所有题目组扁平化
  var sampleIds = Object.entries(globalSampleGids).reduce(function (previous, _ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
      key = _ref8[0],
      _ref8$ = _ref8[1],
      value = _ref8$ === void 0 ? [] : _ref8$;
    return previous.concat(value);
  }, []);
  // 过滤出应该显示的题目
  var showQuestions = questions.filter(function (q) {
    return showQuestion(sampleIds, q.groupIndex, q.sampleGroupIndex);
  });
  var groupIds = {};
  var groupIndex = null;
  var pageBreakIds = [];
  var questionIds = [];
  for (var i = 0; i < showQuestions.length; i++) {
    var question = showQuestions[i];
    var type = question.type,
      gIdx = question.groupIndex; // 如果是分页或者描述题 存到pageBreakIds中
    if (['page_break', 'description'].includes(type)) {
      pageBreakIds.push(i);
    } else if (
    // 如果当前题目的groupIndex存在且在随机出的sampleIds中
    Number.isInteger(gIdx) && sampleIds.includes(gIdx)) {
      groupIds[gIdx] = _toConsumableArray(groupIds[gIdx] || []).concat([i]);
      /*
       *  如果当前题目的groupIndex和全局groupIndex不同, 说明是另一个题目组
       *  groupIndex重新赋值，questionIds用null占位
       *
       */
      if (groupIndex !== gIdx) {
        groupIndex = gIdx;
        questionIds.push(null);
      }
    } else {
      questionIds.push(i);
    }
  }
  questionIds = questionIds.reduce(function (pre, curr) {
    if (curr) {
      var _pre;
      (_pre = pre).push.apply(_pre, [curr]);
      // 未随机题目
    } else {
      for (var _i3 in sampleIds) {
        // 组题目随机
        if (groupIds[sampleIds[_i3]]) {
          pre = pre.concat(groupIds[sampleIds[_i3]]);
          sampleIds.splice(_i3, 1);
          break;
        }
      }
    }
    return pre;
  }, []);
  // 把分页题按索引插入
  for (var _i4 = 0; _i4 < pageBreakIds.length; _i4++) {
    var idx = pageBreakIds[_i4];
    questionIds.splice(idx, 0, idx);
  }
  var realQuestions = [];
  var _iteratorNormalCompletion4 = true;
  var _didIteratorError4 = false;
  var _iteratorError4 = undefined;
  try {
    for (var _iterator4 = questionIds[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
      var _idx3 = _step4.value;
      if (typeChecker.isPageBreak(showQuestions[_idx3])) {
        if (!realQuestions.length || !typeChecker.isPageBreak(realQuestions[realQuestions.length - 1])) {
          realQuestions.push(showQuestions[_idx3]);
        }
      } else {
        realQuestions.push(showQuestions[_idx3]);
      }
    }
  } catch (err) {
    _didIteratorError4 = true;
    _iteratorError4 = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
        _iterator4.return();
      }
    } finally {
      if (_didIteratorError4) {
        throw _iteratorError4;
      }
    }
  }
  return realQuestions;
};
export var sampleDataToQuestions = function sampleDataToQuestions(data) {
  var _data$struct = data.struct,
    questions = _data$struct.questions,
    sample_config = _data$struct.sample_config;
  var newQuestions = questions;
  if (sample_config) {
    if (sample_config.mode === 'shuffle') {
      // 随机乱序
      newQuestions = shuffleQuestions({
        questions: questions
      });
    } else if (sample_config.mode === 'sample') {
      // 抽样展示
      newQuestions = sampleQuestions({
        questions: questions,
        sampleCount: sample_config.sample.sample_count
      });
    } else if (sample_config.mode === 'sample_by_question') {
      // 指定题目范围抽样展示
      newQuestions = sampleQuestionsByGroup({
        questions: questions,
        sampleConfig: sample_config
      });
    }
  }
  return _objectSpread({}, data, {
    struct: _objectSpread({}, data.struct, {
      questions: newQuestions
    })
  });
};
export var getPage = function getPage(_ref9) {
  var p = _ref9.p,
    page = _ref9.page;
  var ret = {
    current: p.current || page.current,
    total: p.total || page.total
  };
  if (ret.current > ret.total) ret.current = ret.total;
  if (ret.current === page.current && ret.total === page.total) return null;
  return {
    page: ret
  };
};
export var scrollToTop = function scrollToTop() {
  if (document.querySelector('.header-pic')) {
    document.querySelector('.header-pic').scrollIntoView();
  } else if (document.querySelector('.header')) {
    document.querySelector('.header').scrollIntoView();
  } else if (document.querySelector('#mobileContainer')) {
    document.querySelector('#mobileContainer').scrollTop = 0;
  } else if (document.querySelector('#pcContainer')) {
    document.querySelector('#pcContainer').scrollTop = 0;
  }
};
export var getPageContentLogic = function getPageContentLogic(questions, current, isNextEnd) {
  var pageQuestions = [];
  var currentNo = 0; // 当前的题目从currentNo开始
  // currentPage: 当前遍历的是第几页的题目
  for (var i = 0, currentPage = 0; i < questions.length; i++) {
    var question = questions[i];
    // 每页的题目都是以page_break开始的
    if (typeChecker.isPageBreak(question)) {
      currentPage++;
      // 如果++之后的页码就是要取的页面页码 则开始取题
      if (currentPage === current) {
        pageQuestions.push(question);
      }
      continue;
    }
    // 如果
    if (currentPage < current) {
      if (question.type !== 'description') {
        currentNo++;
      }
      continue;
    }
    if (currentPage > current) break;
    pageQuestions.push(question);
  }
  return {
    no: currentNo,
    questions: pageQuestions,
    isNextEnd: isNextEnd
  };
};
export var retrieveDataAfterSubmit = function retrieveDataAfterSubmit(_ref10) {
  var res = _ref10.res,
    showQuotaLimit = _ref10.showQuotaLimit,
    id = _ref10.id,
    location = _ref10.location;
  var show_message = res.show_message;
  var title = show_message ? show_message : getLocalText('Submit.success'); // '提交成功'
  var type = 'success';
  if (res.code === 400) {
    title = res.i18n_prompt ? getLocalText("error.".concat(res.i18n_prompt)) // 后端错误提示
    : getLocalText('RequestError.400');
    _message.error(title);
    type = 'error';
    return;
  } else if (res.code > 400 && res.code < 500) {
    title = getLocalText('RequestError.biggerThan400');
    _message.error(title);
    type = 'error';
    return;
  } else if (res.code >= 500) {
    title = getLocalText('RequestError.biggerThan500');
    _message.error(title);
    type = 'error';
    return;
  } else if (res.code === 0) {
    title = res.message;
    _message.error(title);
    type = 'error';
    return;
  } else if (res.code === 299) {
    var _data$hit_quota_strat;
    var _data = res.data,
      rnd = res.rnd,
      server_time = res.server_time;
    window.rnd = rnd;
    window.serverTime = server_time;
    type = 'error';
    _data && ((_data$hit_quota_strat = _data.hit_quota_strategy) === null || _data$hit_quota_strat === void 0 ? void 0 : _data$hit_quota_strat[0]) && showQuotaLimit(_data.hit_quota_strategy[0], _data.quota_strategy);
    // 配额已满
    _message.error(title);
    return;
  }
  var data = res.data;
  if (!data) data = {};
  if (window.isRD) {
    var _data$score;
    data.score = (_data$score = data.score) !== null && _data$score !== void 0 ? _data$score : 1000;
    // localStorage.removeItem(this.state.id); RD就留着吧 方便下次直接提交
  } else {
    // 提交成功的话清除 localStorage中的答案
    try {
      var urlParams = getUrlParams();
      var localStorageId = window.isLocal ? "survey-preview-".concat(urlParams.id) : id;
      localStorage.removeItem(localStorageId);
    } catch (error) {
      console.error('get LSAnswer error: ', error.message);
    }
  }

  // 跳转到指定url的逻辑优先于其他显示
  var _data2 = data,
    score = _data2.score,
    finish_redirect_url = _data2.finish_redirect_url;
  var finishUrl = finish_redirect_url ? decodeURIComponent(encodeURIComponent(finish_redirect_url).replace(/%E2%80%8B/g, '')) : null;
  if (finish_redirect_url) {
    var preParams = getUrlParamsText(location);
    var queries = preParams;
    if (score !== undefined) {
      queries = isEmpty(preParams) ? "score=".concat(score) : preParams.concat("&score=".concat(score));
    }
    if (!/^\?/.test(queries) && !/\?$|\/\?/.test(finish_redirect_url)) {
      queries = queries.replace(/^&/, '');
    } else {
      queries = queries.replace(/^\?/, '');
    }
    finishUrl = queries ? "".concat(finishUrl, "?") : finishUrl;
    window.location.href = finishUrl.concat(encodeURIComponent(queries));
  }
  return {
    data: data,
    score: score,
    title: title,
    type: type
  };
};

// 去掉没有显示的题目的答案
export var filterNonAnswer = function filterNonAnswer(showAnswer, originAnswers, questions) {
  var answers = {};
  var _loop = function _loop(i) {
    var _questions$i = questions[i],
      id = _questions$i.id,
      type = _questions$i.type,
      uuid = _questions$i.uuid,
      custom = _questions$i.custom;
    var thisAnswer = showAnswer[id] || originAnswers[id];
    if (!thisAnswer) return "continue";
    if (['single', 'multi', 'singleSelect'].includes(type) && thisAnswer.selected.length === 0) {
      return "continue";
    }
    if (['short', 'long'].includes(type) && thisAnswer && thisAnswer.text === '') return "continue";
    if (type === 'scale' && thisAnswer.degree === 'undefined') return "continue";
    if (type === 'upload' && thisAnswer.list.length === 0) return "continue";
    if (type === 'location' && thisAnswer.location.length === 0) return "continue";
    // 选项填空 删除没有选择的填空
    if (['single', 'multi'].includes(type)) {
      if (thisAnswer.option_blank) {
        Object.keys(thisAnswer.option_blank).forEach(function (key) {
          if (!thisAnswer.selected.includes(key)) {
            delete thisAnswer.option_blank[key];
          }
        });
      }
    }
    if (type === 'matrix') {
      var lines = custom.lines;
      var selected = thisAnswer.selected;
      var uuidSelected = {};
      lines.forEach(function (line) {
        if (selected[line.id]) {
          uuidSelected[line.uuid] = selected[line.id];
        }
      });
      thisAnswer.uuidSelected = uuidSelected;
    }
    answers[id] = _objectSpread({}, thisAnswer, {
      uuid: uuid
    });
  };
  for (var i = 0; i < questions.length; i++) {
    var _ret = _loop(i);
    if (_ret === "continue") continue;
  }
  answers.rnd = window.rnd;
  answers.server_time = window.serverTime;
  answers.ua = window.navigator.userAgent;
  answers.referer = window.location.origin + window.location.pathname;
  return answers;
};